.add-to-cart-button {
	font-weight: $main-bold;
	color: $dark-green;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	display: flex;
	align-items: baseline;
	gap: 8px;
	&:hover, &:focus {
		color: $brand;
	}
}