.map-preview {
	margin-bottom: $base-spacing;
	a {
		display: block;
	}
	img {
		box-shadow: 0px 22px 44px 0px rgba(0, 0, 0, 0.08);
		border: solid 5px $white;
		margin: 0;
		display: block;
	}
	.map-link {
		text-align: right;
	}
}