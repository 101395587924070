.portapros-calculators {
	background-color: $extra-light-gray;
	.toilet-calculator {
		min-width: 100%;
		padding: 26px 20px 20px;
		transition: transform .5s;
	}
	.calculator-container {
		display: flex;
		overflow: hidden;
	}
	&.events-shown .toilet-calculator {
		transform: translateX(0);
	}
	&.construction-shown .toilet-calculator {
		transform: translateX(-100%);
	}
	.calculator-toggle {
		text-align: center;
		transform: translateY(-50%);
		.toggle-wrapper {
			background-image: map-get($gradients, 'brand');
			border-radius: 4px;
			padding: 8px 10px;
			box-shadow: inset 0 1px 13px rgba(0, 0, 0, 0.5);
			display: inline-flex;
			align-items: center;
		}
		button {
			font-family: $alt-font;
			font-size: rem-calc(18);
			font-weight: $alt-bold;
			color: $white;
			cursor: pointer;
			text-transform: uppercase;
			text-decoration: none;
			line-height: 1;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
			padding: 11px 10px;
			margin-right: 15px;
			border: solid 2px transparent;
			transition: border-color .5s, color .5s;
			&:last-child {
				margin-right: 0;
			}
			&.active, &:hover, &:focus {
				color: $white;
				border-color: $white;
				border-radius: 2px;
			}
		}
	}
	.calculator-contact-form {
		background-color: $brand;
		color: $white;
		padding: 30px;
		h2 {
			font-size: rem-calc(25);
			color: $brand-accent;
			margin-bottom: 10px;
		}
		.highlight {
			font-weight: $main-bold;
			color: $brand-accent;
		}
		.submit {
			text-align: right;
		}
	}
	.calculator {
		label {
			font-family: $alt-font;
			font-size: rem-calc(25);
			color: $brand;
			line-height: 1.2;
			margin-bottom: 30px;
			text-transform: none;
			text-shadow: none;
			span {
				margin-bottom: 16px;
				display: block;
			}
		}
		input[type="text"],
		input[type="number"],
		select {
			height: 50px;
			font-family: $main-font;
			font-size: rem-calc(18);
			color: $gray;
		}
	}
	.results {
		background-color: $brand;
		font-family: $alt-font;
		font-size: rem-calc(25);
		font-weight: $alt-bold;
		line-height: 1.2;
		color: $brand-accent;
		padding: 20px;
		margin: 40px 0 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		p {
			line-height: 1.2;
			flex-basis: 240px;
			margin: 0;
		}
		span {
			font-family: $main-font;
			font-size: rem-calc(60);
			font-weight: $main-bold;
			color: $white;
			line-height: 1;
			padding-left: 20px;
		}
		span.custom-text {
			font-size: rem-calc(24);
			padding-left: 0;
			margin-top: 12px;
			display: block;
			a {
				color: $white;
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}
	.results-disclaimer {
		font-size: rem-calc(14);
		color: $gray;
	}
	.calculator {
		margin-bottom: 48px;
	}
}

@include breakpoint(large) {
	.portapros-calculators {
		.calculator-contact-form {
			form {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
				grid-column-gap: 16px;
				.field-full {
					grid-column: 1 / -1;
				}
			}
		}
		.toilet-calculator {
			padding: 52px 60px 40px;
			display: grid;
			grid-template-columns: 1fr 1.5fr;
			grid-gap: 10%;
		}
		.results {
			position: relative;
			&::after {
				content: '';
				width: 82px;
				height: 55px;
				background-image: url('/images/icons/curved_arrow.svg');
				background-repeat: no-repeat;
				display: block;
				position: absolute;
				right: -52px;
				bottom: -32px;
			}
		}
	}
}