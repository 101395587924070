/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/


blockquote {
	max-width: 800px;
	line-height: 1.5;
	padding: 0;
	border: 0;
	margin: 0 auto;
	p {
		background-color: $purple;
		color: $white;
		padding: 20px;
		border-radius: 0 0 $border-radius;
	}
	cite {
		font-style: normal;
		line-height: 1.25;
		text-align: center;
		span {
			display: block;
		}
		.speaker {
			font-size: 1rem;
			font-family: $alt-font;
			font-weight: $alt-bold;
			color: $purple;
		}
		.title {
			font-size: rem-calc(14);
			line-height: 1.5;
		}
		img {
			margin: 20px 0 0;
		}
		&::before {
			display: none;
		}
	}
}

blockquote.brand {
	p {
		background: $brand;
	}
}

.quote-col-2 {
	display: grid;
	grid-gap: 36px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@include breakpoint(medium) {
	blockquote {
		p {
			padding: 30px;
		}
		cite {
			text-align: right;
			.speaker {
				font-size: rem-calc(21);
				line-height: 1.43;
			}
			.title {

			}
		}
	}
	.quote-col-2 {
		display: grid;
	}
}