/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
.preheader {
	height: 58px;
	background: #2A2A2A;
	font-family: $heading-font;
	font-weight: $heading-bold;
	color: $white;
	padding: 5px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 15px;
		@include breakpoint(600px) {
			gap: 20px;
		}
	}
	.divider {
		color: #676666;
	}
	.links {
		font-size: 1rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 15px;
		@include breakpoint(750px) {
			gap: 40px;
		}
		a {
			color: $white;
			line-height: 1;
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			text-decoration: none;
			&:focus, &:hover {
				color: $brand-accent;
			}
		}
		img {
			margin: 0;
		}
		.extra-text {
			display: none;
			@include breakpoint(580px) {
				display: inline;
			}
		}
		.icon {
			color: #5099A1;
			display: none;
			@include breakpoint(355px) {
				display: inline-block;
			}
			svg {
				width: auto;
				height: 20px;
				@include breakpoint(750px) {
					height: 25px;
				}
			}
		}
	}
	.cart-toggle {
		cursor: pointer;
		position: relative;
		display: inline-block;
		img {
			margin: 0;
			position: relative;
			z-index: 10;
		}
	}
	.cart-toggle-count {
		width: 20px;
		height: 20px;
		background: $brand;
		font-family: $main-font;
		font-size: rem-calc(16);
		font-weight: $main-bold;
		color: $white;
		line-height: 1;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -3px;
		right: -10px;
		z-index: 20;
		span {
			margin-top: -2px;
		}
	}
}

.page-header {
	background-color: $black-trans;
	width: 100%;
	z-index: 300;
	position: relative;
	nav {
		display: none;
	}
	.inner {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	.nav-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.nav-toggle {
			padding-left: 20px;
		}
		a {
			line-height: 1;
		}
	}
	.logo {
		flex-shrink: 0;
		padding-right: 30px;
		margin-bottom: -10px;
		img {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(1100px) {
	.page-header {
		.nav-actions {
			display: none;
		}
		nav {
			display: flex;
		}
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.page-header nav {
	width: 100%;
	min-height: 50px;
	align-items: stretch;
	justify-content: flex-end;
	.dropdown-child {
		background-color: $black-trans;
		width: 240px;
		padding: 12px 15px 15px;
		border-radius: 0 0 $border-radius;
		border-top: solid 1px transparent;
		position: absolute;
		left: -15px;
		top: 100%;
		visibility: hidden;
		opacity: 0;
		z-index: -10;
		transition: opacity .3s, visibility .3s;
		a {
			font-family: $alt-font;
			font-size: rem-calc(18);
			color: $white;
			text-decoration: none;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
		li {
			padding: 0;
			margin-bottom: 8px;
		}
	}
	> ul {
		display: flex;
		align-items: stretch;
		justify-content: flex-end;

	}
	> ul > li {
		height: 100%;
		margin-right: 12px;
		display: inline-block;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
	}
	> ul > li > a,
	> ul > li > .dropdown {
		height: 100%;
		font-family: $alt-font;
		font-weight: $alt-bold;
		font-size: rem-calc(20);
		color: $white;
		text-decoration: none;
		line-height: 1.25;
		padding: 0 12px 3px;
		margin-bottom: 0;
		transition: color .3s;
		display: flex;
		align-items: center;
		&:hover, &:focus, &:focus-within {
			color: $brand-accent;
		}
	}
	> ul > .selected,
	.dropdown-child .selected > a,
	.dropdown.selected,
	.nav-text.selected {
		color: $brand-accent;
		a:hover, a:focus, &:focus-within {
			color: $brand-accent;
		}
	}
	> ul > li > ul > li.selected a {
		color: $brand-accent;
	}
	> ul > li > .dropdown {
		&:hover, &:focus, &:focus-within {
			color: $brand-accent;
		}
		&button {
			cursor: default;
		}
		&::after {
			content: '';
			width: 14px;
			height: 100%;
			background: url('/images/cssimg/menu_caret.svg') no-repeat left top;
			background-size: contain;
			background-position: center calc(50% + 4px);
			margin-left: 7px;
			display: inline-flex;
			transition: transform .3s;
		}
	}
	.highlight-nav {
		background-color: $brand;
	}
	.highlight-tag {
		font-family: $heading-font;
		font-size: rem-calc(14);
		font-weight: $heading-bold;
		background-color: $brand;
		color: $white;
		line-height: 1;
		padding: 3px 6px 6px;
		margin-left: 8px;
		display: inline-block;
	}
	.dropdown-container {
		&:hover, &:focus-within {
			.dropdown-child {
				visibility: visible;
				opacity: 1;
				z-index: 1;
			}
		}
	}
}

@include breakpoint(940px) {
	.page-header nav > ul > li {
		margin-right: 22px;
	}
}

/* @end Navigation */