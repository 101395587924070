.large-review-callout {
	max-width: 650px;
	text-align: center;
	padding: 30px;
	margin: 0 auto;
	background-color: $white;
	border-radius: 13px;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
	position: relative;
	z-index: 50;
	@include breakpoint(800px) {
		padding: 50px 30px 40px;
	}
	&.overlap {
		margin-top: -100px;
	}
	.stars {
		margin-bottom: 13px;
		img {
			margin: 0;
		}
	}
	blockquote {
		p {
			background: none;
			font-family: $heading-font;
			font-size: rem-calc(20);
			font-weight: $heading-bold;
			font-style: italic;
			color: $brand;
			line-height: 1.333;
			padding: 0;
			margin: 0 0 20px;
			@include breakpoint(800px) {
				font-size: rem-calc(30);
			}
		}
		cite {
			font-size: rem-calc(18);
			color: $main-color;
			text-align: center;
			line-height: 1.5;
		}
		.speaker {
			color: $brand;
		}
	}
}

.review-callout-photo {
	background: $white;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
	margin-bottom: $base-spacing;
	border-radius: 17px;
	display: flex;
	flex-wrap: wrap;
	.photo {
		text-align: center;
		padding: 40px 40px 0;
		img {
			margin-bottom: 0;
		}
	}
	.text {
		padding: 40px;
	}
	blockquote {
		p {
			background: none;
			font-family: $heading-font;
			font-size: rem-calc(24);
			font-weight: $heading-bold;
			font-style: italic;
			color: $brand;
			line-height: 1.5;
			padding: 0;
			margin: 0 0 20px;
		}
		cite {
			font-size: rem-calc(18);
			color: $main-color;
			text-align: left;
			line-height: 1.5;
		}
		.speaker {
			color: $brand;
		}
	}
}

.review-callout-photo blockquote p {
	font-size: rem-calc(20);
}

@include breakpoint(600px) {
	.review-callout-photo {
		flex-wrap: nowrap;
		.photo {
			flex-basis: 430px;
			align-self: flex-end;
		}
		.text {
			flex-basis: 870px;
		}
	}
}

.review-cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px 45px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}

.review-card {
	max-width: 500px;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
	margin: 0 auto;
	border-radius: 0 0 20px;
	display: flex;
	flex-direction: column;
	.stars {
		background: linear-gradient(92deg, #006D79 0%, #2A5E64 38.02%, #158895 73.26%, #20717A 100%);
		text-align: center;
		padding: 20px;
		img {
			margin: 0;
		}
	}
	blockquote {
		height: 100%;
		font-size: rem-calc(18);
		line-height: 1.5;
		padding: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 30px;
	}
	cite {
		font-size: rem-calc(18);
		line-height: 1.5;
		.speaker {
			color: $brand;
		}
	}
	p {
		background: none;
		color: $brand;
		line-height: 1.5;
		border-radius: 0;
		padding: 0;
		margin: 0;
	}
}