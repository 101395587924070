.logo-grid {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 40px;
	margin-bottom: $base-spacing;
	img {
		margin: 0;
	}
}

.logo-grid.compact {
	gap: 20px;
}

@include breakpoint(500px) {
	.logo-grid {
		flex-direction: row;
		flex-wrap: wrap;
	}
	.logo-grid.compact {
		justify-content: flex-start;
	}
}

@include breakpoint(1200px) {
	.logo-grid {
		gap: 40px 60px;
	}
}