.alert-bar {
	background-color: $dark-green;
	font-size: rem-calc(16);
	color: $white;
	text-align: center;
	padding: 20px 0;
	p {
		line-height: 1.25;
		margin: 0;
	}
	a {
		color: $white;
		margin-left: 7px;
		border-bottom: solid 2px $brand-accent;
		text-decoration: none;
		transition: border-color .3s;
		&:hover, &:focus {
			color: $white;
			border-color: $white;
			text-decoration: none;
		}
	}
}

@include breakpoint(medium) {
	.alert-bar {
		padding: 15px 0;
	}
}