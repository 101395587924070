.quote-request-form-selector {
	.form-selector-options-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 30px;
	}
	.form-selector-option {
		width: 100%;
		background: $white;
		color: $dark-green;
		text-align: center;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.text {
			padding: 20px;
			@include breakpoint(medium) {
				padding: 30px;
			}
		}
		h3 {
			font-size: rem-calc(20);
			color: $brand;
			margin-bottom: 4px;
		}
		a {
			font-weight: $main-bold;
			font-size: rem-calc(14);
			color: $dark-green;
			margin-top: 15px;
			&:hover, &:focus {
				color: $brand;
			}
		}
		.footer-button {
			width: 100%;
			background-color: $dark-green;
			font-family: $heading-font;
			font-weight: $heading-bold;
			color: $white;
			padding: 15px 30px;
			margin-top: auto;
			transition: background-color .3s;
			cursor: pointer;
			display: block;
			&:hover, &:focus {
				background-color: $brand;
			}
		}
	}
	.go-back-button {
		margin-bottom: 30px;
		button {
			color: $brand;
			text-decoration: none;
			cursor: pointer;
			&:hover, &:focus {
				color: $dark-green;
			}
		}
		img {
			vertical-align: 1px;
			margin: 0 5px 0 0;
		}
	}
	.form-container {
		margin-bottom: 40px;
		h2 {
			margin-bottom: 10px;
		}
		form {
			margin-bottom: 40px;
		}
		.intro-text {
			font-family: $alt-font;
			font-size: rem-calc(17);
			font-weight: $alt-bold;
			color: $brand;
			line-height: 1.29;
			margin-bottom: 30px;
		}
		.icon-group-callout {
			margin-bottom: 40px;
		}
		.testimonial-highlight-container {
			margin-bottom: 40px;
		}
	}
}

@include breakpoint(large) {
	.quote-request-form-selector {
		.form-container {
			.intro-text {
				font-size: rem-calc(21);
				line-height: 1.43;
			}
		}
	}
}