.sub-hero {
	background-color: $off-black;
	color: $white;
	padding: $intro-section-spacing 0;
	h2 {
		color: $white;
		margin-bottom: 8px;
	}
	h3 {
		color: $white;
	}
	.intro {
		max-width: 860px;
		margin: 0 auto 28px;
	}
}