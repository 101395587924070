/*------------------------------------*\
    @group Hero
\*------------------------------------*/

.hero {
	background-color: $black-trans;
	color: $white;
	.hero-content {
		max-width: 635px;
		background-color: $brand-trans;
		padding: 20px;
		border-radius: 0 0 $border-radius;
		> *:last-child {
			margin-bottom: 0;
		}
		a {
			font-weight: $main-bold;
			color: $white;
			text-decoration: underline;
			text-decoration-thickness: 2px;
			text-underline-offset: 4px;
			text-decoration-color: $brand-accent;
			transition: text-decoration-color .3s;
			&:hover, &:focus {
				text-decoration-color: $white;
			}
		}
	}
	h1 {
		color: $white;
	}
	h2 {
		color: $white;
	}
	.inner {
		height: 500px;
		padding-top: 30px;
		padding-bottom: 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: flex-start;
	}
}

@include breakpoint(large) {
	.hero {
		.hero-content {
			padding: 36px;
		}
		.inner {
			height: 700px;
			padding-top: 65px;
			padding-bottom: 65px;
		}
	}
}

//Full hero with no forms
.hero-full {
	@include hero-image-retina($filename: hero);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	&.bg-brown-toilet-row {
		@include hero-image-retina($filename: brown_toilet_row);
	}
	&.bg-travis-leaning {
		@include hero-image-retina($filename: travis_leaning_porta_potty);
	}
	&.bg-grove-hand-wash-stations {
		@include hero-image-retina($filename: grove_hand_wash_stations);
	}
	&.bg-blue-turf {
		@include hero-image-retina($filename: blue_turf);
		.hero-content {
			background: $football-orange;
		}
	}
	&.bg-partygoers-laughing {
	  background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&crop=right&fit=crop&h=500&w=375&a=2');
	  @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	  only screen and (-o-min-device-pixel-ratio: 13/10),
	  only screen and (min-resolution: 120dpi) {
	    background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&crop=right&dpr=2&fit=crop&h=500&q=20&w=375');
	  }
	  @include breakpoint(medium) {
	    & {
	      background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&w=768&h=500&fit=crop');
	      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	      only screen and (-o-min-device-pixel-ratio: 13/10),
	      only screen and (min-resolution: 120dpi) {
	        background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&w=768&h=500&fit=crop&dpr=2&q=20');
	      }
	    }
	  }
	  @include breakpoint(large) {
	    & {
	      background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&w=1440&h=700&fit=crop');
	      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	      only screen and (-o-min-device-pixel-ratio: 13/10),
	      only screen and (min-resolution: 120dpi) {
	        background-image: url('https://portapros.imgix.net/hero/bg_partygoers_laughing.jpg?auto=format&w=1440&h=700&fit=crop&dpr=2&q=20');
	      }
	    }
	  }
	}
	@include breakpoint(large) {
		&.hero-text-small {
			h1, .h1 {
				font-size: rem-calc(36);
				line-height: 1.25;
			}
		}
	}
}

//Hero with form
.hero.hero-form {
	background-image: none;
	background-color: $black-trans;
	.inner {
		height: auto;
		padding-top: 0;
		padding-bottom: 0;
		display: block;
	}
	.hero-content-wrapper {
		height: 500px;
		background-image: url('https://portapros.imgix.net/hero/bg_hero.jpg?auto=format');
		background-size: cover;
		background-position: center center;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		padding: 30px 1.25rem;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
	.hero-form-wrapper {
		padding-top: 16px;
		padding-bottom: 48px;
	}
	form {
		.field-full {
			grid-column: span 2;
		}
		.button {
			margin-top: 16px;
		}
		input[type="text"],
		input[type="email"] {
			height: 34px;
			margin-bottom: 12px;
		}
	}
	p {
		line-height: 1.3;
		margin-bottom: 12px;
	}
}

@include breakpoint(medium) {
	.hero.hero-form {
		.hero-content-wrapper {
			background-image: url('https://portapros.imgix.net/hero/bg_hero_med.jpg?auto=format');
			margin-left: -1.875rem;
			margin-right: -1.875rem;
		}
		form {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(218px,1fr));
			grid-column-gap: 16px;
		}
	}
}

@include breakpoint(830px) {
	.hero.hero-form {
		background-image: url('https://portapros.imgix.net/hero/bg_hero_lrg.jpg?auto=format');
		background-size: cover;
		background-position: center center;
		.hero-content-wrapper {
			background: none;
			margin-left: 0;
			margin-right: 0;
			padding: 0 30px 0 0;
		}
		.hero-form-wrapper {
			background-color: $black-trans;
			padding: 0 0 30px;
			flex-basis: 525px;
			flex-shrink: 1;
			h2 {
				background-color: $brand;
				font-size: rem-calc(25);
				padding-top: 14px;
				padding-bottom: 14px;
			}
			> * {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
		.inner {
			min-height: 700px;
			padding-top: 115px;
			padding-bottom: 65px;
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-end;
			justify-content: space-between;
		}
		form {
			grid-column-gap: 28px;
			.field-full {
				grid-column: 1 / -1;
			}
			.submit {
				text-align: right;
			}
		}
	}
}


//Hero with media object
.hero-media-object {
	background-image: url('https://portapros.imgix.net/hero/bg_trailer_field.jpg?auto=format');
	background-color: #332443;
	background-size: cover;
	background-position: center center;
	color: $white;
	padding-top: 108px;
	h1 {
		color: $white;
	}
	.hero-content {
		margin-bottom: 24px;
	}
	.hero-media {
		min-height: 300px;
		position: relative;
		img {
			margin-bottom: 0;
		}
	}
	.hero-media-details {
		position: absolute;
		right: 0;
		bottom: 94px;
		h2 {
			font-size: rem-calc(25);
			color: $white;
			margin: 0 0 3px;
		}
		p {
			font-size: rem-calc(14);
			color: $brand-accent;
			line-height: 1.3;
			margin: 0;
			span {
				color: $extra-light-gray;
				display: block;
			}
		}
	}
}

@include breakpoint(750px) {
	.hero-media-object {
		background-image: url('https://portapros.imgix.net/hero/bg_trailer_field_med.jpg?auto=format');
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
		}
		.hero-content {
			padding-right: 16px;
			margin-bottom: 80px;
		}
	}
}

@include breakpoint(large) {
	.hero-media-object {
		height: 850px;
		background-image: url('https://portapros.imgix.net/hero/bg_trailer_field_lrg.jpg?auto=format');
		padding-top: 40px;
		.inner {
			height: 100%;
			grid-template-columns: 1fr 1fr;
		}
		.hero-content {
			padding-right: 32px;
		}
		.hero-media {
			text-align: right;
			align-self: end;
		}
		.hero-media-details {
			text-align: left;
			right: auto;
			left: 0;
			bottom: 210px;
		}
	}
}


//Hero with media object (wide image)
.hero-media-object.wide-media {
	background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=540');
	background-color: #175156;
	background-size: cover;
	background-position: center center;
	color: $white;
	padding-top: 108px;
	h1 {
		color: $white;
	}
	.hero-content {
		max-width: 500px;
		margin: 0 auto 24px;
	}
	.hero-media {
		position: relative;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		img {
			margin-bottom: 0;
		}
	}
	.hero-media-details {
		position: absolute;
		right: 0;
		bottom: 94px;
		h2 {
			font-size: rem-calc(25);
			color: $white;
			margin: 0 0 3px;
		}
		p {
			font-size: rem-calc(14);
			color: $brand-accent;
			line-height: 1.3;
			margin: 0;
			span {
				color: $extra-light-gray;
				display: block;
			}
		}
	}
	.hero-link-options {
		a {
			margin-left: 15px;
			&:hover, &:focus {
				color: $white;
			}
		}
	}
}

@include breakpoint(900px) {
	.hero-media-object.wide-media {
		background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=960');
		min-height: 500px;
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
		}
		.hero-content {
			padding-right: 16px;
			margin-bottom: 0;
		}
		.hero-media {
			margin: 0;
		}
	}
}

@include breakpoint(large) {
	.hero-media-object.wide-media {
		background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=1440');
		height: 500px;
		padding-top: 40px;
		.inner {
			height: 100%;
			grid-template-columns: 1fr 1fr;
		}
		.hero-content {
			padding-right: 32px;
		}
		.hero-media {
			text-align: right;
			align-self: end;
		}
		.hero-media-details {
			text-align: left;
			right: auto;
			left: 0;
			bottom: 210px;
		}
	}
}

@include breakpoint(1170px) {
	.hero-media-object.wide-media {
		height: 730px;
		.inner {
			grid-template-columns: 1fr 2fr;
		}
	}
}


//Hero with media object (wide image) overlapping text box
.hero-media-object-overlap {
	background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=540');
	background-color: #175156;
	background-size: cover;
	background-position: center center;
	color: $white;
	padding-top: 50px;
	.hero-content {
		max-width: 500px;
		margin: 0 auto 24px;
	}
	.hero-content-container {
		background: $white;
		padding: 15px 20px 20px;
		border-radius: 0 0 17px 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		h1 {
			font-size: rem-calc(24);
			color: $brand;
			line-height: 1.2;
			margin-bottom: 0;
		}
	}
	.hero-media {
		position: relative;
		img {
			max-width: 400px;
			margin: 0 auto;
			display: block;
		}
	}
}

@include breakpoint(900px) {
	.hero-media-object-overlap {
		background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=960');
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: end;
		}
		.hero-content {
			padding-right: 16px;
			margin-bottom: 30px;
		}
		.hero-content-container {
			margin-right: -70px;
			position: relative;
			z-index: 20;
		}
		.hero-media {
			margin: 0;
			position: relative;
			z-index: 10;
		}
	}
}

@include breakpoint(large) {
	.hero-media-object-overlap {
		background-image: url('https://portapros.imgix.net/hero/bg_downtown_boise_capitol.jpg?auto=format&w=1440');
		padding-top: 40px;
		.inner {
			height: 100%;
			grid-template-columns: 1fr 1fr;
		}
		.hero-content {
			padding-right: 0;
		}
		.hero-media {
			text-align: right;
			align-self: end;
		}
	}
}

@include breakpoint(1070px) {
	.hero-media-object-overlap {
		.inner {
			display: flex;
			align-items: flex-end;
		}
		.hero-media {
			flex-basis: 756px;
			img {
				max-width: 100%;
			}
		}
		.hero-content {
			flex-basis: 534px;
		}
		.hero-content-container {
			padding: 25px 30px 30px;
			margin-right: -70px;
			position: relative;
			z-index: 20;
			h1 {
				font-size: rem-calc(36);
				line-height: 1.11;
			}
		}
	}
}

// Contained hero
.contained-hero {
	margin-top: 30px;
	margin-bottom: $content-section-spacing-small;
	.wrapper {
		border-radius: 17px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		overflow: hidden;
	}
	.hero-form {
		background: linear-gradient(180deg, #006D79 0%, #004B53 100%) $brand;
		color: $white;
		padding: 30px 0;
		h2 {
			color: $white;
		}
		form {
			max-width: 400px;
			margin: 0 auto;
		}
		a {
			color: $white;
			text-decoration: underline;
		}
		.recaptcha-field {
			margin-bottom: 20px;
		}
		.form-col-right {
			padding-bottom: 1px;
		}
	}
	.hero-footer {
		background-color: $white;
		padding: 20px;
		p {
			margin: 0 0 15px;
		}
		a:not(.button) {
			color: $brand;
			&:hover, &:focus-visible {
				color: $brand-accent;
			}
		}
	}
}

@include breakpoint(medium) {
	.contained-hero {
		.hero-footer {
			width: 100%;
			display: flex;
			gap: 30px;
			p {
				margin: 0;
			}
			.action {
				flex-shrink: 0;
			}
		}
	}
}

@include breakpoint(800px) {
	.contained-hero {
		.hero-form {
			form {
				max-width: 100%;
			}
			.form-col-left {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 20px;
			}
		}
	}
}

@include breakpoint(large) {
	.contained-hero {
		margin-bottom: $content-section-spacing;
		.hero-form {
			form {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px 30px;
			}
			.form-col-left {
				grid-column-gap: 30px;
			}
			.form-col-right .field-full {
				height: 100%;
				display: flex;
				flex-direction: column;
				textarea {
					height: 100%;
					margin-bottom: 0;
				}
			}
		}
		.hero-footer {
			padding: 30px;
			p {
				font-size: rem-calc(20);
			}
		}
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .bg-#{$name} {
	@include hero-image-retina($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

//Hero images for form containers
@each $name, $filename in $hero-bg {
  .hero-form.bg-#{$name} {
	@include hero-form-image-retina($name: $name, $filename: $filename);
  }
}

.hero-form.bg-tan-ag-trailer-field {
	.hero-content-wrapper {
		background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=375&h=500&fp-x=.1&fit=crop');
		background-color: #bac1cd;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
				background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=375&h=500&fp-x=.1&fit=crop&dpr=2&q=20');
			}
		}
		@include breakpoint(medium) {
		.hero-content-wrapper {
			background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=766&h=500');
				@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
				only screen and (-o-min-device-pixel-ratio: 13/10),
				only screen and (min-resolution: 120dpi) {
				  background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=766&h=500&dpr=2&q=20');
				}
			}
		}
		@include breakpoint(830px) {
			& {
				background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=1440');
				@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
				only screen and (-o-min-device-pixel-ratio: 13/10),
				only screen and (min-resolution: 120dpi) {
				  background-image: url('https://portapros.imgix.net/hero/bg_tan_agricuture_trailer_in_farm_field.jpg?auto=format&w=1440&dpr=2&q=20');
				}
			}
			.hero-content-wrapper {
			background: none;
		}
	}
}

/* @end Hero */


/*------------------------------------*\
    @group Hero callout
\*------------------------------------*/
.hero-callout-flex .inner {
	min-height: 650px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
}

.hero-callout {
	background: rgba($purple, .95);
	width: 100%;
	border-radius: 0 $border-radius 0 0;
	padding: 20px;
	margin-bottom: -30px;
	margin-top: 35px;
	h2 {
		font-size: rem-calc(20);
		line-height: 1.25;
		margin-bottom: 0;
	}
	p {
		margin-bottom: 12px;
	}
	.heading-icon .icon {
		max-height: 35px;
	}
}

@include breakpoint(large) {
	.hero-callout {
		display: flex;
		justify-content: space-between;
		padding: 36px;
		margin-bottom: -65px;
		.action {
			flex-shrink: 0;
		}
		.text {
			padding-right: 32px;
			display: flex;
			h2 {
				font-size: rem-calc(25);
				margin-bottom: 0;
				line-height: 1.2;
			}
		}
		.heading-icon .icon {
			max-height: 51px;
			margin: 0 20px 0 0;
		}
	}
}
/* @end Hero callout */


// Hero with employee
.hero-employee {
	background: url('https://portapros.imgix.net/hero/service-areas/bg_porta_potty_lineup.jpg?auto=format&w=900&h=500&fit=crop') no-repeat center top #2b435b;
	background-size: 900px 500px;
	width: 100%;
	height: 500px;
	display: flex;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://portapros.imgix.net/hero/service-areas/bg_porta_potty_lineup.jpg?auto=format&w=900&h=500&fit=crop&dpr=2&q=20');
	}
	h1 {
		font-size: rem-calc(34);
		color: $white;
		line-height: 1;
		margin: 0;
	}
	.inner {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		gap: 50px;
	}
	.text {
		max-width: 500px;
	}
	.photo {
		display: none;
	}
}

@include breakpoint(900px) {
	.hero-employee {
		height: 728px;
		background-image: url('https://portapros.imgix.net/hero/service-areas/bg_porta_potty_lineup.jpg?auto=format&w=1440');
		background-size: 1440px 728px;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://portapros.imgix.net/hero/service-areas/bg_porta_potty_lineup.jpg?auto=format&w=1440&dpr=2&q=20');
		}
		h1 {
			font-size: rem-calc(43);
		}
		.text {
			flex-basis: 550px;
		}
		.photo {
			flex-basis: auto;
			flex-grow: 1;
			text-align: center;
			display: block;
			align-self: flex-end;
			position: relative;
			img {
				margin: 0;
			}
		}
	}
}

@include breakpoint(1440px) {
	.hero-employee {
		background-size: cover;
		background-position: center bottom;
	}
}

// Hero media overlap
.hero-media-overlap {
	background: linear-gradient(315deg, #006D79 0%, #19B0A3 100%);
	color: $white;
	padding-top: 120px;
	margin-bottom: 50px;
	h1 {
		font-size: rem-calc(40);
		color: $white;
	}
	.media {
		max-width: 550px;
		background-color: $purple;
		border-radius: 0;
		margin-bottom: -100px;
		overflow: hidden;
		position: relative;
		img {
			margin: 0;
		}
	}
}

@include breakpoint(830px) {
	.hero-media-overlap {
		margin-bottom: 100px;
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
			align-items: center;
		}
	}
}

@include breakpoint(1100) {
	.hero-media-overlap {
		.inner {
			grid-template-columns: 1fr 550px;
			grid-gap: 114px;
		}
		h1 {
			font-size: rem-calc(80);
			margin-bottom: 30px;
		}
		p {
			font-size: rem-calc(28);
		}
	}
}

// Hero BG Video
.hero-bg-video {
	position: relative;
    .autoplay-preview {
        position: relative;
        z-index: 50;
    }
    .hero-video {
    	background-color: $black;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;
		width: 100%;
		max-width: 100%;
		max-height: 1080px;
		margin: 0 auto;
	    position: relative;
		overflow: hidden;
	    &.hide-bg {
	    	background: none !important;
	    }
    }
    .hero-video-play {
        width: 100%;
        max-width: 300px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 60;
        transform: translate(-50%, -50%);
        .play-button {
            color: $brand;
            transition: color .3s;
            cursor: pointer;
            &:hover, &:focus {
                color: $brand-accent;
            }
            svg {
                max-width: 48px;
                height: auto;
                path {
                    transition: fill .3s;
                }
            }
        }
    }
	#hero-video-pause {
		text-align: right;
		display: inline-block;
		cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 60;
		svg {
			max-width: 25px;
			height: auto;
			path {
				transition: fill .3s;
			}
		}
    }
    .hero-video-full {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        opacity: 0;
        z-index: -1;
		transform: translateX(-50%);
        &.visible {
            opacity: 1;
            z-index: 70;
        }
    }
	.hero-video-wrapper {
		max-width: 100%;
		max-height: 1080px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
	}
}

@include breakpoint(700px) {
	.hero-bg-video {
		#hero-video-pause {
			bottom: 20px;
			right: 20px;
			svg {
				max-width: 40px;
			}
		}
	}
}

@include breakpoint(1000px) {
	.hero-bg-video {
		background-color: $black;
	}
}

// Sub hero callout
.sub-hero-callout {
	width: 100%;
	text-align: center;
	.inner {
		background: $brand;
		width: 100%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.heading {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1.25;
		margin: 0 0 30px;
		@include breakpoint(400) {
			font-size: rem-calc(24);
		}
	}
	h2 {
		font-size: rem-calc(20);
		color: $white;
		line-height: 1.25;
		margin-bottom: 20px;
	}
	.action-links {
		width: 100%;
		display: flex;
		gap: 10px 20px;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	a {
		color: $white;
		text-decoration-line: underline;
		text-decoration-thickness: 2px;
		text-decoration-color: $brand-accent;
		transition: text-decoration-color .3s;
		&:hover, &:focus {
			color: $white;
			text-decoration-color: $white;
		}
	}
}

@include breakpoint(850px) {
	.sub-hero-callout {
		max-width: 830px;
		position: absolute;
		z-index: 50;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.inner {
			background: rgba($brand, .85);
			padding: 40px;
		}
		.heading {
			font-size: rem-calc(40);
			line-height: 1;
		}
		h2 {
			font-size: rem-calc(24);
			margin-bottom: 10px;
		}
	}
}

// Hero with content tab in bottom left
.hero-tab {
	min-height: 500px;
	background-position: center;
	background-size: 600px auto;
	display: flex;
	align-items: flex-end;
	position: relative;
	.inner {
		width: 100%;
		position: relative;
	}
	.hero-content {
		max-width: 900px;
		background: $brand-trans;
		color: $white;
		padding: 20px;
		position: relative;
		z-index: 10;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		> *:last-child {
			margin-bottom: 0;
		}
		&.narrow {
			max-width: 654px;
		}
		&.inline {
			display: inline-block;
		}
	}
	.hero-image {
		z-index: 1;
		position: absolute;
		right: 0;
		bottom: 0;
		img {
			max-width: 350px;
			margin: 0;
		}
	}
	h1 {
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.25;
		margin: 0 0 10px;
	}
	p {
		font-size: rem-calc(18);
		line-height: 1.6;
	}
}

@include breakpoint(540px) {
	.hero-tab {
		.hero-content {
			border-radius: 5px 5px 0 0;
			margin: 0;
		}
	}
	.hero-tab-right {
		.hero-content {
			margin-left: auto;
		}
	}
}

@include breakpoint(600px) {
	.hero-tab {
		min-height: 600px;
		background-size: 1000px auto;
		.hero-image {
			img {
				max-width: 500px;
			}
		}
	}
}

@include breakpoint(850px) {
	.hero-tab {
		.hero-content {
			padding: 30px;
		}
		h1 {
			font-size: rem-calc(36);
			line-height: 1.11;
		}
		p {
			font-size: rem-calc(23);
			line-height: 1.56;
		}
	}
}

@include breakpoint(1000px) {
	.hero-tab {
		background-size: 1600px auto;
	}
}

@include breakpoint(1600px) {
	.hero-tab {
		background-size: cover;
	}
}