/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

\*----------------------------------------------------------*/

.content-section-full {
	padding-top: $full-section-spacing-small;
	padding-bottom: $full-section-spacing-small*2;
	&.base {
		padding-bottom: ($full-section-spacing-small*2) - $base-spacing;
	}
}
.content-section-full-small {
	padding-top: 30px;
	padding-bottom: 30px;
	&.base {
		padding-bottom: 7px;
	}
}
.content-section-full-large {
	padding-top: $content-section-spacing-small;
	padding-bottom: $content-section-spacing-small;
	&.base {
		padding-bottom: $content-section-spacing-small - $base-spacing;
	}
}
.intro-section {
	padding-bottom: $intro-section-spacing-small;
	> *:last-child {
		margin-bottom: 0;
	}
	&.base {
		padding-bottom: $intro-section-spacing-small - $base-spacing;
	}
}
.content-section {
	padding-bottom: $content-section-spacing-small;
	&.base {
		padding-bottom: $content-section-spacing-small - $base-spacing;
	}
}
.content-section-top {
	padding-top: $content-section-spacing-small;
	&.base {
		padding-top: $content-section-spacing-small - $base-spacing;
	}
}
.content-sub-section {
	padding-bottom: $content-sub-section-spacing-small;
	&.base {
		padding-bottom: $content-sub-section-spacing-small - $base-spacing;
	}
}
.content-sub-section-short {
	padding-bottom: $content-sub-section-short-spacing-small;
	&.base {
		padding-bottom: $content-sub-section-short-spacing-small - $base-spacing;
	}
}

@include breakpoint(large) {
	.content-section-full {
		padding-top: $full-section-spacing;
		padding-bottom: $full-section-spacing;
		&.base {
			padding-bottom: $full-section-spacing - $base-spacing;
		}
	}
	.content-section-full-large {
		padding-top: $content-section-spacing;
		padding-bottom: $content-section-spacing;
		&.base {
			padding-bottom: $content-section-spacing - $base-spacing;
		}
	}
	.intro-section {
		padding-bottom: $intro-section-spacing;
		&.base {
			padding-bottom: $intro-section-spacing - $base-spacing;
		}
	}
	.content-section {
		padding-bottom: $content-section-spacing;
		&.base {
			padding-bottom: $content-section-spacing - $base-spacing;
		}
	}
	.content-section-top {
		padding-top: $content-section-spacing;
		&.base {
			padding-top: $content-section-spacing - $base-spacing;
		}
	}
	.content-sub-section {
		padding-bottom: $content-sub-section-spacing;
		&.base {
			padding-bottom: $content-sub-section-spacing - $base-spacing;
		}
	}
	.content-sub-section-short {
		padding-bottom: $content-sub-section-short-spacing;
		&.base {
			padding-bottom: $content-sub-section-short-spacing - $base-spacing;
		}
	}
}

// Dark background color sections
.dark-section {
	h1, h2, h3, h4, p, ul {
		color: $white;
	}
}