.content-selector {
	background-color: $extra-light-gray;
	.content-selector-container {
		// padding: 0 15px 15px;
		padding: 15px;
	}
	.content-selector-toggle {
		text-align: center;
		transform: translateY(-50%);
		.toggle-wrapper {
			background-image: linear-gradient(to right, #006d79 0%, #19b0a3 52%, #006d79 100%);
			border-radius: 4px;
			padding: 8px 10px;
			box-shadow: inset 0 1px 13px rgba(0, 0, 0, 0.5);
			display: inline-flex;
			align-items: center;
		}
		button {
			font-family: $alt-font;
			font-size: rem-calc(18);
			font-weight: $alt-bold;
			color: $white;
			text-transform: uppercase;
			line-height: 1;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
			padding: 11px 10px;
			margin-right: 15px;
			border: solid 2px transparent;
			transition: border-color .3s, color .3s;
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				cursor: pointer;
			}
			&.active,
			&:hover,
			&:focus {
				color: $white;
				border-color: $white;
				border-radius: 2px;
			}
		}
	}
	.content-selector-form {
		background-color: $brand;
		color: $white;
		padding: 20px;
		border-radius: 0 0 17px;
		h2 {
			font-size: rem-calc(21);
			color: $white;
			margin-bottom: 10px;
			&.space-top {
				margin-top: 16px;
			}
		}
		.small-label {
			font-weight: $main-normal;
			text-transform: none;
		}
		.highlight {
			font-weight: $main-bold;
			color: $brand-accent;
		}
		.submit {
			text-align: right;
		}
		.recaptcha-field {
			margin-bottom: 16px;
		}
	}
	.results-disclaimer {
		font-size: rem-calc(14);
		color: $gray;
	}
	.calculator {
		margin-bottom: 48px;
	}
}

@include breakpoint(large) {
	.content-selector {
		.content-selector-container {
			padding: 40px;
			// padding: 0 40px 40px;
		}
		.content-selector-form {
			padding: 30px;
			form {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
				grid-gap: 0 30px;
				.field-full {
					grid-column: 1 / -1;
				}
			}
		}
	}
}