.icon-grid-card {
	max-width: 1040px;
	background-color: $off-black;
	color: $white;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	border-radius: $border-radius;
	padding-top: 44px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	z-index: 100;
	&.overlap-top {
		margin-top: -70px;
	}
	&.full-width {
		max-width: 100%;
	}
}
.icon-grid-card-header {
	text-align: center;
	padding: 0 32px;
	h2 {
		color: $white;
		line-height: 1.17;
		margin-bottom: 30px;
	}
}
.icon-grid-card-icons {
	padding: 0 32px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-gap: 48px;
	a {
		display: inline-block;
	}
}
.icon-grid-card-footer {
	background-color: $brand;
	padding: 32px;
	margin-top: 44px;
	p {
		font-size: rem-calc(17);
	}
	a:not(.button) {
		color: $yellow;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
}
.icon-grid-card-item {
	text-align: center;
	.heading {
		font-family: $alt-font;
		font-size: rem-calc(17);
		font-weight: $alt-bold;
		line-height: 1.29;
		display: block;
	}
	a {
		color: $white;
		svg g,
		svg path {
			transition: fill .3s;
		}
		&:hover, &:focus {
			color: $yellow;
			svg g,
			svg path {
				fill: $yellow;
			}
		}
	}
	.tag-item {
		position: relative;
		display: inline-block;
	}
	.new-tag {
		background-color: $brand;
		font-family: $alt-font;
		font-size: rem-calc(18);
		color: $white;
		line-height: 1;
		padding: 3px 6px;
		display: inline-block;
		position: absolute;
		right: -45px;
		top: 5px;
	}
}

@include breakpoint(650px) {
	.icon-grid-card-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.button {
			flex-shrink: 0;
		}
		p {
			margin: 0 20px 0;
		}
	}
}

@include breakpoint(large) {
	.icon-grid-card.overlap-top {
		margin-top: -80px;
	}
	.icon-grid-card-icons {
		padding: 0 110px;
		grid-gap: 60px 96px;
	}
	.icon-grid-card-footer p {
		font-size: rem-calc(17);
	}
}


// Icon grid with no bg
.icon-grid-card.bare {
	max-width: 835px;
	background: none;
	padding: 0;
	box-shadow: none;
	border-radius: 0;
	.icon-grid-card-header h2 {
		color: $main-color;
		margin-bottom: 30px;
		@include breakpoint(medium) {
			margin-bottom: 50px;
		}
	}
	.icon-grid-card-icons {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		padding: 0;
	}
	.icon-grid-card-item a {
		color: $main-color;
		svg g,
		svg path {
			fill: $brand;
		}
		&:hover, &:focus {
			color: $brand;
			svg g,
			svg path {
				fill: $brand-accent;
			}
		}
	}
	.icon-grid-card-footer {
		border-radius: 0 0 17px;
	}
}

@include breakpoint(large) {
	.icon-grid-card.bare {
		.icon-grid-card-icons {
			gap: 60px 50px;
		}
	}
}