/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0;
	margin: 1.25em 0;
	li {
		padding: 0 0 0 20px;
		margin: 0 0 6px;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: '';
			background-color: $brand-accent;
			width: 10px;
			height: 10px;
			display: block;
			position: absolute;
			left: 0;
			top: 8px;
			@include breakpoint(large) {
				top: 9px;
			}
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 24px;
		}
	}
}
ol {
	margin: 20px 0 15px 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		padding-left: 0;
		position: relative;
		&::before {
			display: none;
		}
	}
}

ul.checkmark {
	li {
		padding-left: 30px;
		padding-bottom: 7px;
		&::before {
			content: '';
			width: 22px;
			height: 17px;
			background-image: url('/images/icons/checkmark.svg');
			background-color: transparent;
			background-repeat: no-repeat;
			top: 4px;
		}
	}
	&.compact li {
		margin-bottom: 0;
	}
}

ul.checkmark-green {
	li {
		padding-left: 30px;
		padding-bottom: 0;
		&::before {
			content: '';
			width: 22px;
			height: 17px;
			background-image: url('/images/icons/checkmark_green.svg');
			background-color: transparent;
			background-repeat: no-repeat;
			top: 4px;
		}
	}
}

ul.x-red {
	li {
		padding-left: 25px;
		padding-bottom: 0;
		&::before {
			content: '';
			width: 17px;
			height: 17px;
			background-image: url('/images/icons/x_red.svg');
			background-color: transparent;
			background-repeat: no-repeat;
			top: 5px;
		}
	}
}

ul.grid-list-2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 0 10px;
}

@include breakpoint(medium) {
	ul.split-list-2,
	ol.split-list-2 {
		overflow: hidden;
		margin-left: 0;
		padding-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
		overflow: hidden;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
		&::before {
			display: none;
		}
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
	a {
		text-decoration: none;
	}
}
/* @end */

.split-icon-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 20px 40px;
	margin-bottom: 40px;
	li {
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.list-icon {
		width: 28px;
		color: #62D0DF;
		display: inline-flex;
		svg {
			width: auto;
			height: 20px;
		}
	}
}