.background-section {
	background-image: url('https://portapros.imgix.net/media-sections/bg_purple.jpg?auto=format');
	background-size: cover;
	background-position: center center;
	background-color: #786489;
	min-height: 600px;
	color: $white;
	padding-bottom: 48px;
	padding-top: 48px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	h2 {
		color: $white;
	}
	.inner {
		width: 100%;
		> * {
			max-width: 467px;
		}
	}
}

@include breakpoint(large) {
	.background-section {
		background-image: url('https://portapros.imgix.net/media-sections/bg_purple_lrg.jpg?auto=format');
		min-height: 550px;
		padding: 0;
		align-items: center;
		.inner > * {
			max-width: 593px;
		}
	}
}

.background-section {
	&.bg-plant-close-up {@include background-section-image-cdn(bg_plant_close_up, jpg, 960, 600, 1440, 550);}
	&.bg-commercial-fence-line {@include background-section-image(bg_commercial_fence_line);}
	&.bg-row-toilets-handwash-stations {@include background-section-image(bg_row_toilets_handwash_stations);}
	&.bg-residential-construction-site {@include background-section-image(bg_residential_construction_site);}
	&.bg-football-field-toilet-row {@include background-section-image(bg_football_field_toilet_row);}
	&.bg-mountain-wedding-setting {@include background-section-image(bg_mountain_wedding_setting);}
	&.bg-football-field-toilets {@include background-section-image(bg_football_field_toilets);}
	&.bg-emergency-vehicle {@include background-section-image(bg_emergency_vehicle);}
}


.background-section-form {
	.inner {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 30px;
	}
	.form-wrapper {
		background-color: $black-trans;
		padding-bottom: 30px;
		h2 {
			background-color: $brand;
			font-size: rem-calc(25);
			padding-top: 14px;
			padding-bottom: 14px;
		}
		> * {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	form {
		.field-full {
			grid-column: span 2;
		}
		.button {
			margin-top: 16px;
		}
		input[type="text"],
		input[type="email"] {
			height: 34px;
			margin-bottom: 12px;
		}
	}
	.text {
		margin-bottom: 20px;
	}
}

@include breakpoint(medium) {
	.background-section-form {
		form {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
			grid-column-gap: 16px;
		}
	}
}

@include breakpoint(830px) {
	.background-section-form {
		.inner {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
		}
		.text {
			flex-basis: 50%;
		}
		.form-wrapper {
			flex-basis: 50%;
			flex-shrink: 1;
		}
		form {
			grid-column-gap: 28px;
			.field-full {
				grid-column: 1 / -1;
			}
			.submit {
				text-align: right;
			}
		}
	}
}

@include breakpoint(large) {
	.background-section-form {
		padding: 108px 0;
	}
}


//Bg overlap section
.bg-overlap-section {
	.bg-media {
		height: 350px;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
			max-width: none;
		}
	}
	.bg-media + * {
		margin-top: -160px;
		position: relative;
	}
}

@include breakpoint(medium) {
	.bg-overlap-section {
		.bg-media {
			margin-left: -1.875rem;
			margin-right: -1.875rem;
		}
	}
}

@include breakpoint(large) {
	.bg-overlap-section {
		.bg-media {
			height: 630px;
			margin-left: auto;
			margin-right: auto;
		}
		.bg-media + * {
			margin-top: -172px;
		}
	}
}