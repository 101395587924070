.product-cards {
	margin: 40px auto;
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	@include breakpoint(750px) {
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(950px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.product-card {
	width: 100%;
	max-width: 425px;
	background: $white;
	text-align: center;
	padding: 36px 30px;
	margin: 0 auto;
	box-shadow: 0 4px 9px rgba(0, 0, 0, 0.08);
	position: relative;
	display: flex;
	flex-direction: column;
	.card-content {
		margin-top: auto;
	}
	.tag {
		background: $brand;
		font-family: $heading-font;
		font-size: rem-calc(14);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1;
		padding: 5px 10px 8px;
		border-radius: 0 0 0 11px;
		position: absolute;
		top: 0;
		right: 0;
	}
	.title {
		font-size: rem-calc(24);
		line-height: 1.25;
		margin-bottom: 10px;
	}
	.price {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $brand;
		margin-bottom: 20px;
	}
	.optional-action {
		padding-top: 8px;
		button {
			// font-weight: $main-bold;
			color: $brand;
			cursor: pointer;
			border-bottom: solid 2px $brand;
			padding-bottom: 2px;
			transition: color .3s, border-color .3s;
			&:hover, &:focus {
				color: $purple;
				border-color: $purple;
			}
		}
	}
}