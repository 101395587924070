.accordion {
    margin: 0 0 $base-spacing;
    position: relative;
}

.accordion-heading {
    .heading {
        font-family: $main-font;
        font-size: rem-calc(18);
        font-weight: $main-bold;
        color: $brand;
        line-height: 1.1;
        margin: 0;
    }
    button {
        width: 100%;
        color: $brand;
        text-align: left;
        padding: 15px 24px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        transition: color .3s;
        .accordion-trigger-icon {
            display: inline-flex;
            transition: transform .2s;
        }
    }
    button.open {
        .accordion-trigger-icon {
            transform: rotate(180deg);
        }
    }
    .text {
        font-weight: $main-bold;
        display: inline-flex;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        @include breakpoint(700px) {
            flex-wrap: nowrap;
        }
    }
}

.accordion-item {
    background: $off-white;
    color: $main-color;
    border: 2px solid #D3D3D3;
    margin-bottom: 12px;
    transition: border-color .3s;
    &:hover, &.accordion-open {
        border-color: $brand;
    }
}

.accordion-content-wrapper {
    padding: 0 24px 12px;
    > *:last-child {
        margin-bottom: 0;
    }
    @include breakpoint(medium) {
        padding-left: 30px;
        padding-right: 30px;
    }
}