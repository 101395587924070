.section-intro {
	text-align: center;
	.text-large {
		font-size: rem-calc(18);
		@include breakpoint(800px) {
			font-size: rem-calc(23);
		}
	}
	&.align-left {
		text-align: left;
	}
	&.narrow {
		max-width: 900px;
		margin: 0 auto;
	}

}
.section-intro-content {
	max-width: 465px;
	margin: 0 auto 36px;
}

@include breakpoint(large) {
	.section-intro-content {
		max-width: 635px;
	}
}