.section-intro {
	text-align: center;
	.text-large {
		font-size: rem-calc(24);
	}

}
.section-intro-content {
	max-width: 465px;
	margin: 0 auto 36px;
}

@include breakpoint(large) {
	.section-intro-content {
		max-width: 635px;
	}
}