.alert-bar {
	background-color: $purple;
	font-size: rem-calc(16);
	color: $white;
	text-align: center;
	padding: 20px 0;
	p {
		line-height: 1.25;
		margin: 0;
	}
	a {
		color: $white;
		border-bottom: solid 1px $white;
		transition: border .3s;
		&:hover, &:focus {
			border-color: transparent;
		}
	}
}

@include breakpoint(medium) {
	.alert-bar {
		padding: 15px 0;
	}
}