/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $darker-gray;
    color: $white;
    padding-top: 36px;
    h2 {
        font-size: rem-calc(20);
        color: $white;
        margin-bottom: 7px;
        &.new-tag {
            &::before {
                content: 'NEW!';
                background-color: $brand-accent;
                font-size: rem-calc(14);
                font-weight: $main-normal;
                color: $white;
                padding: 0 3px 1px;
                margin-right: 8px;
                vertical-align: 2px;
                display: inline-block;
            }
        }
    }
    ul {
        margin: 0;
    }
    a:not(.button) {
        &:hover, &:focus {
            color: $yellow;
        }
    }
    .logo {
        margin-bottom: 48px;
        img {
            margin: 0;
        }
    }
    .nav-list {
        padding-bottom: 21px;
        margin-bottom: 21px;
        border-bottom: solid 1px $off-black;
        &:last-child {
            border: 0;
        }
    }
    .contact-info {
        margin-bottom: 36px;
        p {
            margin-bottom: 4px;
        }
        .title {
            width: 65px;
            font-weight: $main-bold;
            color: $off-white;
            display: inline-block;
        }
        .button {
            margin-top: 21px;
        }
    }
    .social-info {
        margin-bottom: 36px;
        h2 {
            margin-bottom: 16px;
        }
        a {
            line-height: 1;
            margin-right: 20px;
            display: inline-block;
            path {
                transition: fill .3s;
            }
            &:hover, &:focus {
                path {
                    fill: $yellow;
                }
            }
        }
    }
    .footer-top {
        padding-bottom: 48px;
    }
    .footer-bottom {
        background-color: $off-black;
        font-size: rem-calc(14);
        color: $light-gray;
        text-align: center;
        padding: 24px 0;
        p {
            margin: 0;
        }
        .back-to-top {
            display: inline-block;
            padding-left: 16px;
            margin-bottom: 21px;
            position: relative;
            &::before {
                @include css-triangle(5px, $light-gray, up);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@include breakpoint(768px) {
    .page-footer {
        .nav-group {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 62px;
            padding-bottom: 45px;
            margin-bottom: 48px;
            border-bottom: solid 1px $off-black;
        }
        .nav-list {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
        }
        .aside {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 62px;
        }
        .contact-info {
            font-size: 1rem;
            margin-bottom: 0;
            h2 {
                margin-bottom: 12px;
            }
        }
        .footer-bottom {
            padding: 12px 0;
            .inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .back-to-top {
                margin-bottom: 0;
                order: 1;
            }
        }
    }
}

@include breakpoint(1200px) {
    .page-footer {
        .footer-top-content {
            display: grid;
            grid-template-columns: 2fr minmax(300px, auto);
            grid-gap: 120px;
        }
        .aside {
            display: block;
        }
        .contact-info {
            margin-bottom: 36px;
        }
        .nav-group {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
            display: block;
        }
        .nav-list {
            padding-bottom: 18px;
            margin-bottom: 28px;
            border-bottom: solid 1px $off-black;
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            ul {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}