@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__slides {
	will-change: auto;
}

.glide__slides, .glide__slide {
	width: 100vw;
}

.glide {
	max-width: 100%;
}

.glide__arrow {
	top: 100px;
	padding: 0;
	box-shadow: none;
	border: 0;
	img {
		margin: 0;
	}
}

.glide__arrow--left {
	left: 0;
}

.glide__arrow--right {
	right: 0;
	transform: translateY(-50%) rotate(180deg);
}

.stacked-media-slide {
	text-align: center;
	img {
		max-width: 190px;
	}
	.slide-content {
		max-width: 470px;
		margin: 0 auto;
	}
}

@include breakpoint(medium) {
	.glide__arrow {
		top: 200px;
	}

	.stacked-media-slide {
		img {
			max-width: 100%;
			margin-bottom: 50px;
		}
	}
}

@include breakpoint(large) {
	.stacked-media-slide {
		text-align: left;
		.inner-slide {
			max-width: 890px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.slide-content {
			padding-left: 40px;
		}
		img {
			margin-bottom: 0;
		}
	}
}

//Employee testimonials slider
.employee-testimonials-slider {
	max-width: 400px;
	background: $light-tan;
	border-radius: 0 0 20px;
	margin: 0 auto;
	.slide-media {
		text-align: center;
		img {
			margin: 0;
		}
	}
	.slide-content {
		padding: 20px 20px 25px;
	}
	blockquote {
		p {
			background: none;
			color: $main-color;
			padding: 0;
			margin: 0 0 32px;
			&::before {
				content: '';
				background: url('/images/icons/quotes.svg');
				width: 60px;
				height: 43px;
				margin-right: 7px;
				display: inline-block;
			}
		}
		cite {
			font-family: $alt-font;
			font-size: rem-calc(24);
			font-weight: $alt-bold;
			color: $brand;
			text-align: left;
		}
		.title {
			font-size: rem-calc(18);
			font-weight: $alt-normal;
			color: $main-color
		}
	}
	.glide__bullets {
		left: auto;
		right: 20px;
		top: auto;
		bottom: 20px;
		transform: none;
	}
	.glide__bullet {
		background-color: $light-gray;
		width: 20px;
		height: 20px;
		margin: 0 7px;
		border: 0;
		box-shadow: none;
		&:hover, &:focus {
			background-color: lighten($purple, 10%);
			border: 0;
		}
	}
	.glide__bullet--active {
		background-color: $purple;
		&:hover, &:focus {
			background-color: $purple;
		}
	}
}

@include breakpoint(750px) {
	.employee-testimonials-slider {
		max-width: 100%;
		.inner-slide {
			display: flex;
		}
	}
}

@include breakpoint(950px) {
	.employee-testimonials-slider {
		.slide-content {
			padding: 45px;
		}
	}
}

@include breakpoint(1100px) {
	.employee-testimonials-slider {
		blockquote {
			max-width: 680px;
			p {
				font-size: rem-calc(24);
			}
		}
		.slide-content {
			padding: 90px 90px 45px;
		}
	}
}