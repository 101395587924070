/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/

table {
	margin-bottom: 2rem;
	th {
		font-size: 1.125em;
		line-height: 1.2;
		border: solid 1px $white;
	}
	td {
		border: solid 1px $white;
	}
	.center {
		text-align: center;
	}
	&.narrow-table {
		max-width: 1000px;
	}
}

table.bare-table {
	background: none;
	margin-bottom: $base-spacing ;
	tbody {
		background: none;
		border: 0;
	}
	tr {
		background: none;
	}
	td {
		background: none;
		padding: 0 40px 3px 0;
		border: 0;
		vertical-align: top;
		&:last-child {
			padding-right: 8px;
		}
	}
}

table.info-table {
	@include font-size(small);
	thead {
		background-color: $extra-light-gray;
		th {
			vertical-align: top;
			border: 1px solid $extra-light-gray;
		}
	}
	tbody tr:nth-child(2n) {
		background-color: $white;
	}
	td {
		vertical-align: top;
		border: solid 1px $extra-light-gray;
	}
	ul li::before {
		width: 6px;
		height: 6px;
		top: 8px;
	}
}

.overflow-scroll-table-container {
	width: 100%;
	overflow-x: auto;
	table {
		min-width: 840px;
	}
}