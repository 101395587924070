.emergency-response-template {
	p + ul {
		margin-top: -20px;
	}
	ul {
		margin: 0;
	}
	.hero {
		.hero-aside {
			background-color: $black-trans;
			padding: 20px;
		}
		.inner {
			height: 800px;
			display: grid;
			grid-gap: 32px;
			align-items: center;
			justify-content: center;
		}
		.hero-content {
			margin-top: auto;
		}
		.hero-aside {
			margin-bottom: auto;
		}
	}
	.background-section {
		position: relative;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background-color:
			rgba(65, 55, 74, 0.6);
			position: absolute;
			left: 0;
			top: 0;
		}
		.inner {
			z-index: 10;
		}
	}
}

@include breakpoint(800px) {
	.emergency-response-template {
		.hero {
			.inner {
				height: 700px;
				grid-template-columns: 1fr 1fr;
				align-items: end;
				grid-gap: 50px;
			}
			.hero-content {
				margin-top: 0;
			}
			.hero-aside {
				margin-bottom: 0;
			}
		}
	}
}

@include breakpoint(900px) {
	.emergency-response-template {
		.background-section {
			height: 600px;
			.inner {
				height: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 48px;
				align-items: center;
			}
		}
	}
}

@include breakpoint(1050px) {
	.emergency-response-template .hero .inner {
		grid-gap: 100px;
	}
}