.blog-overview-title {
	font-size: rem-calc(28);
	color: $brand;
	line-height: 1.6;
	border-bottom: solid 2px $extra-light-gray;
	margin-bottom: 50px;
}

// Post previews
.blog-post-preview-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 50px 30px;
}

.blog-post-preview-image {
	margin-bottom: 32px;
	img {
		width: auto;
		border-radius: 10px;
		margin: 0;
	}
}


// Featured post preview
.blog-featured-post-preview {
	width: 100%;
	margin-bottom: 100px;
}

.blog-featured-post-preview-image {
	margin-bottom: 24px;
	img {
		border-radius: 10px;
		margin: 0;
		@include breakpoint(500px) {
			width: auto;
		}
	}
}

// Post generic classes
.blog-post-title {
	color: $brand;
	a {
		color: $brand;
		text-decoration: none;
		&:hover, &:focus {
			color: $dark-green;
		}
	}
}

.blog-post-date {
	font-size: rem-calc(16);
	color: $dark-green;
	margin-bottom: 0;
}

.blog-post-author {
	font-size: rem-calc(16);
	display: flex;
	align-items: flex-start;
	img {
		margin: 0 10px 0 0;
		border-radius: 4px;
	}
	p {
		line-height: 1.4;
		margin: 0;
	}
}

.blog-post-author-name {
	font-weight: $main-bold;
	display: block;
}


// Blog pagination
.blog-pagination {
	font-family: $alt-font;
	font-size: rem-calc(18);
	margin-top: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.blog-pagination-numbers {
	line-height: 1;
	margin: 0 20px;
	flex-shrink: 1;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	li {
		text-align: center;
		line-height: 22px;
		padding: 0 5px;
		&::before {
			display: none;
		}
	}
	a {
		width: 22px;
		height: 22px;
		color: $brand;
		line-height: 16px;
		display: inline-block;
		text-decoration: none;
	}
	.active a {
		background-color: $brand;
		color: $white;
	}
}

.pagination-button:not(.button) {
	width: 100px;
	color: $dark-gray;
	text-align: center;
	text-decoration: none;
	padding: 9px 10px 11px;
	line-height: 1;
	border: solid 1px $gray;
	border-radius: 4px;
	&:hover, &:focus {
		border-color: $brand;
	}
}

// Main blog post

.blog-post {
	blockquote {
		font-size: rem-calc(24);
		font-style: italic;
		line-height: 1.5;
		padding-left: 16px;
		margin: 24px 0;
		border-left: solid 4px $brand-accent;
	}
}
.blog-post-footer {
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	> * {
		margin: 10px 0;
	}
	.blog-post-author {
		padding-right: 15px;
	}
}

.blog-post-image {
	img {
		margin: 10px 0 50px;
		border-radius: 10px;
		@include breakpoint(500px) {
			width: auto;
		}
	}
}

.blog-post-shares {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	p {
		margin: 0;
		padding-right: 10px;
	}
	img {
		margin: 0;
		padding-left: 10px;
	}
}

.blog-post-return {
	margin-top: 30px;
	a {
		color: $brand;
		text-decoration: none;
		&:hover, &:focus {
			color: $dark-green;
		}
	}
	img {
		vertical-align: 1px;
		margin: 0 5px 0 0;
	}
}

@include breakpoint(750px) {
	.blog-post-preview-list {
		grid-template-columns: 1fr 1fr;
	}
}

@include breakpoint(1000px) {
	.blog-featured-post-preview {
		display: flex;
		align-items: flex-start;
	}

	.blog-featured-post-preview-image {
		flex-basis: 50%;
	}

	.blog-featured-post-preview-text {
		padding-left: 30px;
		flex-basis: 50%;
	}
}

@include breakpoint(1200px) {
	.blog-featured-post-preview-image {
		flex-basis: auto;
		flex-shrink: 0;
		flex-basis: 858px;
	}

	.blog-featured-post-preview-text {
		flex-basis: auto;
		flex-basis: 442px;
	}
}