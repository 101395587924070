.icon-heading-highlight {
	width: 100%;
	font-size: rem-calc(20);
	font-weight: $alt-bold;
	color: $brand;
	text-wrap: pretty;
	line-height: 1.25;
	border-bottom: solid 4px $brand-accent;
	padding-bottom: 10px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	.icon {
		background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
		width: 50px;
		height: 50px;
		padding: 12px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	img {
		margin: 0;
	}
}

@include breakpoint(large) {
	.icon-heading-highlight {
		font-size: rem-calc(25);
		line-height: 1.2;
	}
}