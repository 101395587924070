.cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, .5fr));
	justify-content: center;
	grid-gap: 36px;
	margin: 0 auto;
	.card {
		width: 100%;
		background-color: $white;
		text-align: center;
		padding: 40px 30px 30px;
		margin: 0 auto;
		border: solid 6px $white;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		.heading {
			font-family: $alt-font;
			font-size: rem-calc(18);
			font-weight: $alt-bold;
			line-height: 1.25;
			margin: 0 0 20px;
		}
		img {
			margin-bottom: 13px;
		}
	}
}

@include breakpoint(800px) {
	.cards {
		max-width: 970px;
		grid-column-gap: 15px;
	}
}

@include breakpoint(large) {
	.cards {
		grid-gap: 30px;
	}
}

.bg-heading-cards {
	.card {
		min-height: 400px;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 6px 6px 14px;
		text-align: left;
		.heading {
			width: 100%;
			min-height: 115px;
			color: $white;
			padding: 10px 14px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		h3 {
			font-size: rem-calc(22);
			color: $white;
			margin-bottom: 6px;
		}
		.subheading {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			img {
				margin: 0 5px 0 0;
			}
		}
		.content {
			font-size: rem-calc(16);
			p {
				line-height: 1.5;
				margin-bottom: 0;
				+ p {
					margin-top: 20px;
				}
			}
			margin-bottom: 30px;
		}
		.action {
			font-family: $alt-font;
			font-weight: $alt-bold;
			font-size: rem-calc(16);
			margin-top: auto;
			margin-left: auto;
			a {
				color: $brand-alt;
			}
		}
	}
	.heading.service-technician {
		background-color: $brand-alt;
	}
	.heading.outside-sales,
	.heading.customer-care,
	.heading.marketing-manager {
		background-color: $purple;
	}
}

// Text focused cards
.text-cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px;
}

.text-card:not(.button) {
	max-width: 280px;
	background: $white;
	font-family: $heading-font;
	color: $main-color;
	text-decoration: none;
	padding: 20px 15px;
	border: 6px solid #FFFFFF;
	margin: 0 auto;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
	transition: box-shadow .3s;
	display: block;
	&:hover, &:focus-visible {
		color: $main-color;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
	img {
		margin-bottom: 10px;
	}
	.heading {
		font-size: rem-calc(18);
		color: $dark-green;
		line-height: 1.3;
		margin-bottom: 12px;
	}
	.title {
		font-size: rem-calc(14);
		font-weight: $main-bold;
		line-height: 1.3;
		margin-bottom: 0;
	}
	.date {
		font-size: rem-calc(14);
		line-height: 1.6;
		margin-bottom: 0;
	}
}

@include breakpoint(775px) {
	.text-card {
		max-width: 304px;
		padding: 36px 24px;
	}
}

// Combo cards
.combo-cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: 50px 40px;
	@include breakpoint(650px) {
		grid-template-columns: 1fr 1fr;
		.span-2 {
			grid-column: span 2;
		}
	}
	@include breakpoint(1000px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.combo-card {
	max-width: 404px;
	width: 100%;
	background: $white;
	margin: 0 auto;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.heading {
		margin-bottom: 10px;
	}
	.media {
		img {
			margin: 0;
		}
	}
	.text {
		padding: 30px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.action {
		margin-top: auto;
		a {
			background: $dark-green;
			font-family: $heading-font;
			font-size: rem-calc(20);
			font-weight: $heading-bold;
			color: $white;
			line-height: 1;
			text-align: center;
			text-decoration: none;
			padding: 20px 30px;
			display: block;
			transition: background-color .3s;
			&:hover, &:focus {
				background-color: $brand;
			}
		}
	}
}

.callout-card-container {
	container-name: callout-card;
	container-type: inline-size;
}

.callout-card {
	width: 100%;
	height: 100%;
	max-width: 852px;
	border-radius: 5px;
	overflow: hidden;
	margin: 0 auto;
	.heading {
		color: $brand-accent;
		margin-bottom: 14px;
	}
	.text {
		background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
		color: $white;
		padding: 30px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.media {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			margin: 0;
		}
	}
}

@container callout-card (min-width: 550px) {
	.callout-card {
		display: flex;
		align-items: stretch;
		.text {
			flex-basis: 525px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
		.media {
			flex-basis: 330px;
		}
	}
}