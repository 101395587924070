.tippy-tooltip-button {
    display: inline-block;
}

.tippy-box {
    background: darken($brand, 10%);
    padding: 15px;
    p {
        line-height: 1.3;
        margin-bottom: 10px;
    }
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: darken($brand, 10%);
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: darken($brand, 10%);
  }
  .tippy-box[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: darken($brand, 10%);
  }
  .tippy-box[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: darken($brand, 10%);
  }