.media-collage {
	text-align: center;
	img {
		margin-bottom: 35px;
	}
}

@include breakpoint(700px) {
	.media-collage-row {
		display: flex;
		> img:first-child {
			width: 60%;
			padding-right: 18px;
		}
		> img:last-child {
			width: 40%;
			padding-left: 18px;
		}
	}
	.media-collage-row.flip {
		> img:first-child {
			width: 40%;
			padding-right: 18px;
		}
		> img:last-child {
			width: 60%;
			padding-left: 18px;
		}
	}
}