@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__slides {
	will-change: auto;
}

.glide__slides, .glide__slide {
	width: 100vw;
}

.glide {
	max-width: 100%;
}

.glide__arrow {
	top: 100px;
	padding: 0;
	box-shadow: none;
	border: 0;
	img {
		margin: 0;
	}
}

.glide__arrow--left {
	left: 0;
}

.glide__arrow--right {
	right: 0;
	transform: translateY(-50%) rotate(180deg);
}

.stacked-media-slide {
	text-align: center;
	img {
		max-width: 190px;
	}
	.slide-content {
		max-width: 470px;
		margin: 0 auto;
	}
}

@include breakpoint(medium) {
	.glide__arrow {
		top: 200px;
	}

	.stacked-media-slide {
		img {
			max-width: 100%;
			margin-bottom: 50px;
		}
	}
}

@include breakpoint(large) {
	.stacked-media-slide {
		text-align: left;
		.inner-slide {
			max-width: 890px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.slide-content {
			padding-left: 40px;
		}
		img {
			margin-bottom: 0;
		}
	}
}

//Employee testimonials slider
.employee-testimonials-slider {
	max-width: 400px;
	background: $extra-light-gray;
	border-radius: 0 0 20px;
	margin: 0 auto;
	.slide-media {
		text-align: center;
		img {
			margin: 0;
		}
	}
	.slide-content {
		padding: 20px 20px 25px;
	}
	blockquote {
		p {
			background: none;
			color: $main-color;
			padding: 0;
			margin: 0 0 32px;
			&::before {
				content: '';
				background: url('/images/icons/quotes.svg');
				width: 60px;
				height: 43px;
				margin-right: 7px;
				display: inline-block;
			}
		}
		cite {
			font-family: $alt-font;
			font-size: rem-calc(24);
			font-weight: $alt-bold;
			color: $brand;
			text-align: left;
		}
		.title {
			font-size: rem-calc(18);
			font-weight: $alt-normal;
			color: $main-color
		}
	}
	.glide__bullets {
		left: auto;
		right: 20px;
		top: auto;
		bottom: 20px;
		transform: none;
	}
	.glide__bullet {
		background-color: $light-gray;
		width: 20px;
		height: 20px;
		margin: 0 7px;
		border: 0;
		box-shadow: none;
		&:hover, &:focus {
			background-color: lighten($purple, 10%);
			border: 0;
		}
	}
	.glide__bullet--active {
		background-color: $purple;
		&:hover, &:focus {
			background-color: $purple;
		}
	}
}

@include breakpoint(750px) {
	.employee-testimonials-slider {
		max-width: 100%;
		.inner-slide {
			display: flex;
		}
	}
}

@include breakpoint(950px) {
	.employee-testimonials-slider {
		.slide-content {
			padding: 45px;
		}
	}
}

@include breakpoint(1100px) {
	.employee-testimonials-slider {
		blockquote {
			max-width: 680px;
			p {
				font-size: rem-calc(24);
			}
		}
		.slide-content {
			padding: 90px 90px 45px;
		}
	}
}

//Quote testimonials slider
.glide-wrapper.full-width {
	margin: 0 -1.25rem;
}
.quote-slider {
	max-width: 650px;
	border-radius: 5px;
	margin: 0 auto;
	&.top-border-round {
		overflow: hidden;
		border-radius: 5px 5px 0 0;
	}
	.glide__slide {
		background: map-get($gradients, 'brand');
		height: auto;
	}
	.slide-media {
		text-align: center;
		padding: 0 0 30px;
		img {
			margin: 0;
		}
	}
	.inner-slide {
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 51;
		&.image-bottom {
			justify-content: space-between;
			.slide-media {
				padding-bottom: 0;
			}
		}
	}
	.slide-content {
		padding: 50px 30px 30px;
	}
	blockquote {
		p {
			background: none;
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.5;
			padding: 0;
			margin: 0 0 20px;
			&::before,
			&::after {
				content: '';
				background-color: $brand-accent;
  				mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none"><path d="M9.72315 2.7907C7.5179 3.28904 6.11456 4.38538 6.11456 5.63123C6.11456 6.27907 6.41527 6.62791 7.41766 7.22591C8.77088 8.02326 9.37231 9.1196 9.37231 10.7143C9.37231 13.1063 7.41766 15 4.96181 15C2.10501 15 0 12.5083 0 9.01993C0 4.68439 2.8568 1.04651 7.06683 0L9.72315 2.7907ZM21 2.7907C18.8449 3.28904 17.4415 4.38538 17.4415 5.63123C17.4415 6.27907 17.6921 6.57807 18.7446 7.22591C20.0477 8.02326 20.6993 9.1196 20.6993 10.7143C20.6993 13.1063 18.7446 15 16.2888 15C13.3819 15 11.327 12.5083 11.327 9.01993C11.327 4.68439 14.1838 1.04651 18.3437 0L21 2.7907Z" fill="currentColor"/></svg>');
  				mask-size: 21px 15px;
  				mask-repeat: no-repeat;
				width: 21px;
				height: 15px;
				padding-bottom: 20px;
				margin-right: 7px;
				display: inline-block;
			}
			&::after {
				padding-bottom: 0;
				margin-right: 0;
				margin-left: 7px;
				margin-bottom: 3px;
				transform: rotate(180deg);
			}
		}
		cite {
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $white;
			text-align: left;
		}
		.title {
			font-size: rem-calc(16);
			font-weight: $alt-normal;
		}
	}
	.glide__arrows {
		position: absolute;
		bottom: 30px;
		transform: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 50;
		&.next {
			right: 20px;
		}
		&.previous {
			left: 20px;
		}
	}
	.glide-arrow {
		cursor: pointer;
	}
	.mcalvain-slide {
		background: linear-gradient(315deg, #080200 0%, #BB3F01 100%);
		blockquote p {
			&::before, &::after {
				background-color: #F06723;
			}
		}
	}
	.opera-idaho-slide {
		background: linear-gradient(315deg, #1C0101 0%, #400 100%);
		blockquote p {
			&::before, &::after {
				background-color: #724242;;
			}
		}
	}
	.red-aspen-slide {
		background: linear-gradient(315deg, #5F001E 0%, #B72755 100%);
		blockquote p {
			&::before, &::after {
				background-color: #FE6E9C;
			}
		}
	}
	.desert-sage-slide {
		background: linear-gradient(315deg, #02205F 0%, #011336 100%);
		blockquote p {
			&::before, &::after {
				background-color: #808080;
			}
		}
	}
	.renaissance-remodeling-slide {
		background: linear-gradient(315deg, #8C253A 0%, #41111B 100%);
		blockquote p {
			&::before, &::after {
				background-color: #EF4165;
			}
		}
	}
}

@include breakpoint(900px) {
	.glide-wrapper.full-width {
		margin: 0;
	}
	.quote-slider {
		max-width: 100%;
		.inner-slide {
			padding: 0 81px;
			flex-direction: row;
			align-items: center;
			gap: 35px;
			&.image-bottom .slide-media {
				align-self: flex-end;
				padding-bottom: 0;
			}
		}
		.slide-content {
			padding: 30px 0;
			flex-basis: 780px;
			order: 1;
		}
		.slide-media {
			padding: 18px 0;
			flex-basis: 225px;
			order: 0;
		}
		.glide__arrows {
			top: 50%;
			bottom: auto;
			&.next {
				right: 30px;
			}
			&.previous {
				left: 30px;
			}
		}
	}
}

@include breakpoint(1200px) {
	.quote-slider {
		blockquote {
			p {
				font-size: rem-calc(24);
			}
		}
		.inner-slide {
			padding: 0 108px;
			gap: 70px;
		}
	}
}


// Quote slider with footer and small image
.quote-slider-light-wrapper {
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.quote-slider-light {
	// max-width: 650px;
	border-radius: 5px;
	margin: 0 auto;
	.glide__slide {
		background: $white;
		height: auto;
	}
	.slide-media {
		text-align: center;
		padding: 0 0 30px;
		img {
			border-radius: 5px;
			margin: 0;
		}
	}
	.inner-slide {
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 51;
		&.image-bottom {
			justify-content: space-between;
			.slide-media {
				padding-bottom: 0;
			}
		}
	}
	.slide-content {
		padding: 50px 30px 30px;
	}
	blockquote {
		p {
			background: none;
			font-size: rem-calc(20);
			color: $brand;
			line-height: 1.5;
			padding: 0;
			margin: 0 0 20px;
			&::before,
			&::after {
				content: '';
				background-color: $brand-accent;
  				mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none"><path d="M9.72315 2.7907C7.5179 3.28904 6.11456 4.38538 6.11456 5.63123C6.11456 6.27907 6.41527 6.62791 7.41766 7.22591C8.77088 8.02326 9.37231 9.1196 9.37231 10.7143C9.37231 13.1063 7.41766 15 4.96181 15C2.10501 15 0 12.5083 0 9.01993C0 4.68439 2.8568 1.04651 7.06683 0L9.72315 2.7907ZM21 2.7907C18.8449 3.28904 17.4415 4.38538 17.4415 5.63123C17.4415 6.27907 17.6921 6.57807 18.7446 7.22591C20.0477 8.02326 20.6993 9.1196 20.6993 10.7143C20.6993 13.1063 18.7446 15 16.2888 15C13.3819 15 11.327 12.5083 11.327 9.01993C11.327 4.68439 14.1838 1.04651 18.3437 0L21 2.7907Z" fill="currentColor"/></svg>');
  				mask-size: 21px 15px;
  				mask-repeat: no-repeat;
				width: 21px;
				height: 15px;
				padding-bottom: 20px;
				margin-right: 7px;
				display: inline-block;
			}
			&::after {
				padding-bottom: 0;
				margin-right: 0;
				margin-left: 7px;
				margin-bottom: 3px;
				transform: rotate(180deg);
			}
		}
		cite {
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $brand;
			text-align: left;
		}
		.title {
			font-size: rem-calc(16);
			font-weight: $alt-normal;
		}
	}
	.glide__arrows {
		position: absolute;
		bottom: 30px;
		transform: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 50;
		&.next {
			right: 20px;
		}
		&.previous {
			left: 20px;
		}
	}
	.glide-arrow {
		cursor: pointer;
	}
}

.quote-slider-footer {
	width: 100%;
	background: $extra-light-gray;
	text-align: center;
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 14px 10px;
	align-items: center;
	justify-content: center;
	> * {
		margin-bottom: 0;
	}
}

@include breakpoint(900px) {
	.quote-slider-light {
		max-width: 100%;
		.inner-slide {
			padding: 0 81px;
			flex-direction: row;
			align-items: center;
			gap: 35px;
			&.image-bottom .slide-media {
				align-self: flex-end;
				padding-bottom: 0;
			}
		}
		.slide-content {
			padding: 60px 0;
			flex-basis: 780px;
			order: 1;
		}
		.slide-media {
			padding: 18px 0;
			flex-basis: 277px;
			order: 0;
		}
		.glide__arrows {
			top: 50%;
			bottom: auto;
			&.next {
				right: 30px;
			}
			&.previous {
				left: 30px;
			}
		}
	}
	.quote-slider-footer {
		padding: 25px;
		flex-direction: row;
	}
}

@include breakpoint(1200px) {
	.quote-slider-light {
		blockquote {
			p {
				font-size: rem-calc(24);
			}
		}
		.inner-slide {
			padding: 0 86px;
			gap: 50px;
		}
	}
}

// Slider footer bar
.slider-footer-bar {
	background: $extra-light-gray;
	max-width: 650px;
	width: auto;
	padding: 20px;
	margin: 0 -1.25rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;
	&.round-corners {
		border-radius: 0 0 5px 5px;
	}
	p {
		line-height: 1;
	}
	> *,
	.star-rating-group > * {
		margin: 0;
	}
	.star-rating-group {
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
	}
}

@include breakpoint(650px) {
	.slider-footer-bar {
		margin: 0 auto;
	}
}

@include breakpoint(900px) {
	.slider-footer-bar {
		max-width: 100%;
		width: 100%;
		margin: 0;
	}
}

// Simple image gallery slider
.image-gallery-slider-wrapper {
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	overflow: hidden;
}

.image-gallery-slider {
	position: relative;
	img {
		margin: 0;
	}
	.glide__arrows {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 50;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
		button {
			width: 50px;
			height: 50px;
			background: $brand;
			border-radius: 50%;
			cursor: pointer;
		}
		svg {
			margin-right: 5px;
		}
		&.next {
			left: auto;
			right: 30px;
			svg {
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
}

.image-gallery-slider-callout {
	background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
	color: $white;
	text-align: center;
	padding: 54px;
	> *:last-child {
		margin-bottom: 0;
	}
	h2 {
		font-size: rem-calc(50);
		color: $white;
		margin-bottom: 10px;
	}
	p {
		font-size: rem-calc(28);
		line-height: 1.5;
	}
}

@include breakpoint(1000) {
	.image-gallery-slider-callout {
		padding: 108px 54px;
		h2 {
			font-size: rem-calc(80);
		}
	}
}