.team-popup {
	width: auto;
	max-width: 1300px;
	background-color: #006d79;
	background-image: map-get($gradients, 'brand');
	color: $white;
	border-radius: $border-radius;
	overflow: hidden;
	position: relative;
	padding: 20px 20px 0;
	margin: 20px auto;
	.mfp-close {
		width: 41px;
		height: 41px;
		background-color: $brand-accent;
		font-size: 41px;
		color: $white;
		opacity: 1;
		top: 10px;
		right: 10px;
		line-height: 41px;
		border-radius: 50%;
		transition: background-color .3s;
		&:hover, &:focus {
			background-color: $dark-green;
		}
	}
	h2 {
		color: $white;
		.title {
			font-size: rem-calc(20);
			color: $brand-accent;
			display: block;
		}
	}
	a:not(.button) {
		color: $white;
		display: flex;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
	.contact-details {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		span {
			padding-right: 18px;
			margin-right: 10px;
			position: relative;
			&::after {
				content: '|';
				color: $brand-accent;
				position: absolute;
				right: 0;
				top: 0;
			}
			&:last-child {
				padding-right: 0;
				margin-right: 0;
				&::after {
					display: none;
				}
			}
		}
		path {
			fill: currentColor;
		}
	}
	.highlight-text {
		font-weight: $main-bold;
		color: $extra-light-gray;
	}
	.text {
		margin-top: 24px;
	}
	.image {
		text-align: center;
		img {
			margin: 0;
		}
	}
	.pill-group {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 5px;
		margin-top: 5px;
		margin-bottom: 24px;
	}
	.pill-item {
		background-color: $dark-green;
		font-family: $alt-font;
		font-size: rem-calc(14);
		line-height: 1;
		border-radius: 20px;
		padding: 3px 10px 6px;
		display: inline-block;
	}
	&.attach-image-right .image {
		margin-right: -20px;
		text-align: right;
	}
}

@include breakpoint(1000px) {
	.team-popup {
		.bio-content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: end;
		}
		.text {
			align-self: start;
		}
		h2.title {
			font-size: rem-calc(25);
		}
	}
}