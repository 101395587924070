// Address grid
.address-grid-container {
	container-name: address-grid-container;
	container-type: inline-size;
}

.address-blocks {
    display: grid;
    gap: 30px 20px;
}

.address-block {
	.heading {
		margin-bottom: 10px;
	}
    > *:last-child {
        margin-bottom: 0;
    }
}

@container address-grid-container (min-width: 450px) {
	.address-blocks {
        grid-template-columns: 1fr 1fr;
    }
}