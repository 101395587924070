.image-text-list {
	.heading {
		color: $brand;
	}
}
.image-text-list-item {
	margin-bottom: 72px;
	&:last-child {
		margin-bottom: 0;
	}
}