.aside-callout-section {
	padding: 48px 0;
	.inner {
		max-width: 1030px;
	}
	.main-item {
		max-width: 335px;
		background-color: $brand;
		color: $white;
		border-radius: 0 0 $border-radius;
		padding: 30px;
		margin: 0 auto 36px;
		h2 {
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.25;
			margin-bottom: 11px;
		}
		p {
			font-size: rem-calc(16);
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
			margin: 0;
		}
	}
	.aside-item {
		max-width: 500px;
		margin: 0 auto;
		.aspect-item {
			margin-bottom: 36px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		h3 {
			font-size: rem-calc(24);
			line-height: 1.17;
			color: $brand;
			display: flex;
			align-items: center;
			.icon {
				padding-left: 10px;
			}
			img {
				height: 30px;
				margin: 0;
			}
		}
		p {
			color: $brand;
			margin: 0;
		}
	}
}

.aside-callout-section.full-width {
	.inner {
		max-width: 85rem;
	}
}

@include breakpoint(800px) {
	.aside-callout-section {
		.inner {
			display: grid;
			align-items: center;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 72px;
		}
		.aside-item {
			max-width: 100%;
		}
	}
	.aside-callout-section.full-width .inner {
		grid-template-columns: auto 1fr;
		align-items: start;
	}
}

@include breakpoint(large) {
	.aside-callout-section {
		padding: 120px 0;
		.main-item {
			max-width: 414px;
			padding: 40px 55px;
			margin-bottom: 0;
			h2 {
				font-size: rem-calc(25);
				line-height: 1.2;
			}
		}
		.aside-item {
			.aspect-item {
				margin-bottom: 30px;
			}
			h3 {
				font-size: rem-calc(36);
				margin-bottom: 7px;
			}
		}
	}
	.aside-callout-section.full-width .inner {
		grid-template-columns: auto 1fr;
		align-items: start;
		.main-item {
			max-width: 500px;
		}
	}
}

//Aside callout section contained
.aside-callout-section.rounded {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	margin-left: -1.25rem;
	margin-right: -1.25rem;
	.button {
		margin-top: 36px;
	}
}

@include breakpoint(medium) {
	.aside-callout-section.rounded {
		padding-left: 1.875rem;
		padding-right: 1.875rem;
		margin-left: -1.875rem;
		margin-right: -1.875rem;
	}
}

@include breakpoint(800px) {
	.aside-callout-section.rounded {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 72px;
	}
}

@include breakpoint(large) {
	.aside-callout-section.rounded {
		padding: 108px;
	}
}

@include breakpoint(1300px) {
	.aside-callout-section.rounded {
		border-radius: $border-radius;
		margin-left: auto;
		margin-right: auto;
	}
}

// Media section full width
.media-section-full {
	background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
	color: $white;
	h2 {
		color: $white;
	}
	.media {
		width: 100%;
		max-width: 544px;
		padding-top: 30px;
		margin: 0 auto;
		img {
			margin: 0;
		}
	}
	.text {
		width: 100%;
		max-width: 570px;
		padding-top: 30px;
		padding-bottom: 30px;
		margin: 0 auto;
	}
}

@include breakpoint(800px) {
	.media-section-full {
		.inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 50px;
		}
		.media {
			margin: 0;
			flex-basis: 544px;
			align-self: flex-end;
		}
		.text {
			flex-basis: 570px;
			margin: 0;
		}
	}
}