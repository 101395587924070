// Social media cards
.social-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	@include breakpoint(600px) {
		grid-gap: 30px;
	}
}

.social-card {
	max-width: 413px;
	color: $white;
	padding: 20px 15px;
	border: 7px solid $white;
	margin: 0 auto;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
	transition: box-shadow .3s;
	&:hover, &:focus-visible, &:focus {
		color: $white;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
	&.facebook {
		background-color: map-get($social, facebook);
	}
	&.linkedin {
		background-color: map-get($social, linkedin);
	}
	&.twitter {
		background-color: map-get($social, twitter);
	}
	.logo {
		color: $white;
		text-align: center;
		line-height: 1;
		margin-bottom: 30px;
	}
	img {
		box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
		margin-bottom: 44px;
	}
	.text {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $main-bold;
		text-align: center;
		line-height: 1.5;
		text-shadow: 0px 1px 1px rgba(0, 109, 121, 0.5);
		margin-bottom: 0;
	}
}


@include breakpoint(775px) {
	.social-card {
		padding: 36px 24px;
	}
}