.page-heading {
	padding-top: 54px;
	.inner {
		height: 100%;
		display: flex;
		align-items: flex-end;
	}
	h1 {
		background-color: $brand;
		color: $white;
		padding: 16px 20px 24px;
		margin: 0;
		border-radius: 0 0 $border-radius;
		display: inline-block;
		span {
			font-family: $main-font;
			font-weight: $main-normal;
			font-size: rem-calc(18);
			margin-top: 20px;
			display: block;
		}
	}
}

@include breakpoint(large) {
	.page-heading {
		padding-top: 108px;
	}
}