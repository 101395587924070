.service-map-section {

}

.service-area-search {
    width: 100%;
    background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
    color: $white;
    text-align: left;
    padding: 30px 20px;
    margin: 0 auto;
    .inner-content {
        max-width: 850px;
        margin: 0 auto;
    }
    .heading {
        font-size: rem-calc(20);
        color: $white;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    .sub-label {
        font-size: rem-calc(14);
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 0;
    }
    #pac-input {
        height: 40px;
        margin-bottom: 0;
    }
    input,
    input::placeholder {
        color: $brand;
    }
    a:not(.button) {
        color: $brand-accent;
        &:hover, &:focus, &:focus-visible {
            color: $white;
        }
    }
}

.service-area-search.light {
    background: $extra-light-gray;
    color: $brand;
    .heading {
        color: $brand;
    }
    input {
        border: solid 1px $brand;
    }
    a:not(.button) {
        color: $brand;
        &:hover, &:focus, &:focus-visible {
            color: $dark-green;
        }
    }
    .service-area-search-result {
        a:not(.button) {
            text-decoration-color: $brand-accent;
            &:hover, &:focus, &:focus-visible {
                color: $brand;
                text-decoration-color: $brand;
            }
        }
    }
    form {
        label {
            color: $brand;
        }
        input.readonly,
        input.readonly:focus,
        input.readonly:focus-visible,
        input.readonly:hover {
            color: $brand;
        }
    }
}

.service-area-search-result {
    margin-top: 30px;
    a:not(.button) {
        color: $white;
        text-decoration-line: underline;
        text-decoration-color: $brand-accent;
        text-decoration-thickness: 2px;
        transition: text-decoration-color .3s;
        &:hover, &:focus {
            color: $white;
            text-decoration-color: $white;
        }
    }
    .icon-inline {
        margin-bottom: 20px;
        display: flex;
        align-items: start;
        gap: 10px;
        img {
            margin: 0;
        }
    }
    .buttons {
        display: flex;
        align-items: start;
        gap: 20px 30px;
        .button {
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            margin: 0;
            transition: .3s background-color;
        }
        img {
            margin: 0;
        }
    }
    .request-new-service-area-form {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px 30px;
        .field-full {
            grid-column: 1 / -1;
        }
        input {
            margin: 0;
        }
        input.readonly,
        input.readonly:focus,
        input.readonly:focus-visible,
        input.readonly:hover {
            background: transparent;
            height: auto;
            color: $white;
            box-shadow: none;
            border: 0;
            padding: 0;
        }
    }
}

@include breakpoint(1000px) {
    .service-area-search {
        padding: 50px 30px;
        .heading {
            font-size: rem-calc(25);
            line-height: 1.2;
        }
    }
}