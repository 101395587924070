.link-list {
    container-type: inline-size;
}

.link-list-item {
    margin-bottom: 30px;
    .heading {
        font-family: $main-font;
        font-size: rem-calc(16);
        font-weight: $main-normal;
        line-height: 1.33;
        margin-bottom: 4px;
        a {
            text-decoration: none;
        }
    }
    .title {
        font-size: rem-calc(14);
        font-weight: $main-bold;
        line-height: 1.33;
        margin-bottom: 4px;
    }
    .date {
        background: #D4E7EA;
        font-size: rem-calc(12);
        margin: 0;
        line-height: 1;
        padding: 4px 10px;
        border-radius: 190px;
        display: inline-block;
    }
}
.link-list-item.media-item {
    .heading {
        &::before {
            content: '';
            background: url('/images/icons/link.svg') no-repeat left top;
            background-size: 100%;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            display: inline-block;
        }
        &.pdf::before {
            background-image: url('/images/icons/pdf.svg');
            width: 15px;
        }
    }
}

@container (min-width: 25rem) {
    .link-list-item.media-item {
        display: flex;
        gap: 20px;
        align-items: flex-start;
        justify-content: space-between;
        .media {
            flex-basis: 175px;
        }
        .text {
            flex-basis: 418px;
        }
        img {
            margin-bottom: 0;
        }
    }
  }