.body-wrapper {
	position: relative;
	&.relative-nav .page-header {
		position: relative;
	}
}
.off-canvas-nav {
	background-color: $brand;
	width: 100%;
	max-width: 330px;
	height: 100%;
	padding-right: 20px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 250;
	transform: translateX(-100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	overflow: scroll;
	&::after {
		display: none;
	}
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	.nav-header {
		margin-bottom: 38px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.nav-toggle {
			padding-left: 20px;
		}
		img {
			margin: 0;
		}
	}
	nav {
		padding-left: 20px;
		li {
			font-size: rem-calc(16);
			padding-left: 0;
			margin-bottom: 7px;
			&:last-child {
				margin-bottom: 17px;
			}
		}
		a, .dropdown {
			text-decoration: none;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
		> ul > li {
			margin-top: 17px;
		}
		> ul > li > a,
		> ul > li > .dropdown,
		> ul > li > .dropdown > a {
			font-family: $alt-font;
			font-weight: $alt-bold;
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.25;
			margin-bottom: 7px;
			transition: color .3s;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
		> ul > li > .dropdown {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 30px;
			position: relative;
			> a {
				margin-bottom: 0;
			}
		}
		> ul > li > button.dropdown {
			cursor: pointer;
		}
		.dropdown-indicator {
			width: 20px;
			height: 20px;
			line-height: 1;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: transform .3s;
			&::after {
				content: '';
				background: url('/images/cssimg/menu_caret.svg') no-repeat left top;
				width: 19px;
				height: 12px;
				display: block;
				transition: transform .3s;
			}
		}
		.dropdown > a.selected {
			color: $brand-accent;

		}
		.dropdown.selected .dropdown-indicator::after {
			transform: rotate(-180deg);
		}
		.dropdown-child {
			margin: 0;
			height: 0;
			overflow: hidden;
			transition: height .3s ease-in-out;
			a {
				color: $white;
				&:hover, &:focus {
					color: $brand-accent;
				}
			}
			&.is-visible {
				height: auto;
				display: block;
				transition: height .3s ease-in-out;
			}
			li:first-child {
				margin-top: 7px;
			}
			.selected > a {
				color: $brand-accent;
			}
		}
		.highlight-tag {
			font-family: $heading-font;
			font-size: rem-calc(14);
			font-weight: $heading-bold;
			background-color: $white;
			color: $brand;
			line-height: 1;
			padding: 1px 6px 4px;
			margin-left: 8px;
			display: inline-block;
		}
	}
	.cta {
		background-color: $white;
		border-radius: 0 0 $border-radius;
		padding: 20px;
		margin-top: 27px;
		h2 {
			font-size: rem-calc(17);
			color: $brand;
			margin-bottom: 6px;
		}
		p {
			@include font-size(small);
			margin-bottom: 10px;
		}
	}
}
.body-content {
	background-color: $white;
	transition: transform .3s;
	position: relative;
	z-index: 100;
}

body.offscreen-nav-visible,
body.modal-visible {
	position: fixed;
	width: 100%;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $black;
		opacity: .75;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&.modal-overlay::before {
		background: $black;
		opacity: .7;
	}
}