.filter-card-list {
	.filter-buttons {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 50px;
		button {
			font-size: rem-calc(16);
			font-weight: $main-bold;
			color: $brand;
			line-height: 1.3;
			padding: 4px 7px 5px;
			margin-bottom: 10px;
			border-radius: 4px;
			display: inline-block;
			cursor: pointer;
			transition: background-color .3s, color .1s;
			&.selected, &:hover, &:focus {
				background-color: $brand;
				color: $white;
			}
			&.selected {
				font-weight: bold;
			}
		}
	}
}

.filter-card-leaving {
	border: solid 10px red;
}