.testimonial-highlight-grid {
	max-width: 620px;
	margin-left: -1.25rem;
	margin-right: -1.25rem;
	display: grid;
	gap: 45px;
}

.testimonial-highlight-container {
	width: 100%;
	max-width: 620px;
	margin: 0 auto;
	container-name: testimonial-highlight-container;
	container-type: inline-size;
}

.testimonial-highlight {
	max-width: 620px;
	margin: 0 auto;
	overflow: hidden;
	.main-testimonial {
		background: linear-gradient(315deg, #000 0%, #AA5713 100%);
		padding: 30px 30px 0;
		&.bg-brand-gradient {
			background: linear-gradient(315deg, #00353C 0%, #004D58 100%);
			blockquote > p {
				&::before,
				&::after {
					background-color: $white;
				}
			}
		}
		&.bg-brand-green-gradient {
			background: linear-gradient(315deg, #003514 0%, #008733 100%);
			blockquote > p {
				&::before,
				&::after {
					background-color: $white;
				}
			}
		}

	}
	blockquote {
		max-width: 100%;
		padding: 0 0 30px;
		> p {
			background: none;
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.5;
			padding: 0;
			margin: 0 0 20px;
			&::before,
			&::after {
				content: '';
				background-color: #F58021;
  				mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none"><path d="M9.72315 2.7907C7.5179 3.28904 6.11456 4.38538 6.11456 5.63123C6.11456 6.27907 6.41527 6.62791 7.41766 7.22591C8.77088 8.02326 9.37231 9.1196 9.37231 10.7143C9.37231 13.1063 7.41766 15 4.96181 15C2.10501 15 0 12.5083 0 9.01993C0 4.68439 2.8568 1.04651 7.06683 0L9.72315 2.7907ZM21 2.7907C18.8449 3.28904 17.4415 4.38538 17.4415 5.63123C17.4415 6.27907 17.6921 6.57807 18.7446 7.22591C20.0477 8.02326 20.6993 9.1196 20.6993 10.7143C20.6993 13.1063 18.7446 15 16.2888 15C13.3819 15 11.327 12.5083 11.327 9.01993C11.327 4.68439 14.1838 1.04651 18.3437 0L21 2.7907Z" fill="currentColor"/></svg>');
  				mask-size: 21px 15px;
  				mask-repeat: no-repeat;
				width: 21px;
				height: 15px;
				padding-bottom: 20px;
				margin-right: 7px;
				display: inline-block;
			}
			&::after {
				padding-bottom: 0;
				margin-right: 0;
				margin-left: 7px;
				margin-bottom: 3px;
				transform: rotate(180deg);
			}
			&.small {
				font-size: 1rem;
				line-height: 1.5;
			}
		}
		cite {
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $white;
			text-align: left;
		}
		.title {
			font-size: rem-calc(16);
			font-weight: $alt-normal;
			display: block;
		}
	}
	.image {
		text-align: center;
		img {
			max-width: 200px;
			margin: 0;
		}
	}
	.footer {
		width: 100%;
		background: $extra-light-gray;
		text-align: center;
		padding: 30px;
		display: flex;
		flex-direction: column;
		gap: 14px 10px;
		align-items: center;
		justify-content: center;
		> * {
			margin-bottom: 0;
		}
	}
}

@container testimonial-highlight-container (min-width: 550px) {
	.testimonial-highlight {
		border-radius: 5px;
		.main-testimonial {
	        display: flex;
	        align-items: center;
	        gap: 30px;
	        .image {
	        	flex-basis: 200px;
	        	order: 0;
	        	align-self: flex-end;
	        	img {
	        		max-width: 100%;
	        	}
	        }
	        blockquote {
	        	flex-basis: 330px;
	        	order: 1;
	        }
		}
		.footer {
			padding: 25px;
			flex-direction: row;
		}
    }
}

@container testimonial-highlight-container (min-width: 620px) {
	.testimonial-highlight {
		blockquote {
			> p {
				font-size: rem-calc(24);
			}
		}
    }
}

@include breakpoint(550px) {
	.testimonial-highlight-grid {
		margin: 0 auto;
	}
}

@include breakpoint(890px) {
	.testimonial-highlight-grid {
		max-width: 100%;
		grid-template-columns: 1fr 1fr;
	}
}


.testimonial-highlight-container.full {
	max-width: 100%;
	.testimonial-highlight {
		max-width: 100%;
	}
	.main-testimonial {
		max-width: 100%;
	}
}

@include breakpoint(550px) {
	.testimonial-highlight-container.full {
		.main-testimonial blockquote {
			flex-grow: 1;
		}
	}
}


.testimonials-list {
	padding: 0 30px;
	@include breakpoint(550px) {
		padding: 0;
	}
}

.testimonial-simple {
	margin-bottom: 38px;
	blockquote {
		max-width: 100%;
		> p {
			background: none;
			font-size: rem-calc(18);
			color: $main-color;
			line-height: 1.6;
			padding: 0;
			margin: 0 0 12px;
			&::before,
			&::after {
				content: '';
				background-color: $dark-green;
  				mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none"><path d="M9.72315 2.7907C7.5179 3.28904 6.11456 4.38538 6.11456 5.63123C6.11456 6.27907 6.41527 6.62791 7.41766 7.22591C8.77088 8.02326 9.37231 9.1196 9.37231 10.7143C9.37231 13.1063 7.41766 15 4.96181 15C2.10501 15 0 12.5083 0 9.01993C0 4.68439 2.8568 1.04651 7.06683 0L9.72315 2.7907ZM21 2.7907C18.8449 3.28904 17.4415 4.38538 17.4415 5.63123C17.4415 6.27907 17.6921 6.57807 18.7446 7.22591C20.0477 8.02326 20.6993 9.1196 20.6993 10.7143C20.6993 13.1063 18.7446 15 16.2888 15C13.3819 15 11.327 12.5083 11.327 9.01993C11.327 4.68439 14.1838 1.04651 18.3437 0L21 2.7907Z" fill="currentColor"/></svg>');
  				mask-size: 21px 15px;
  				mask-repeat: no-repeat;
				width: 21px;
				height: 15px;
				padding-bottom: 20px;
				margin-right: 7px;
				display: inline-block;
			}
			&::after {
				padding-bottom: 0;
				margin-right: 0;
				margin-left: 7px;
				margin-bottom: 3px;
				transform: rotate(180deg);
			}
		}
		cite {
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $main-color;
			text-align: left;
			display: flex;
			align-items: center;
			gap: 10px;
			img {
				margin: 0;
			}
		}
		.title {
			font-size: rem-calc(16);
			font-weight: $alt-normal;
			display: block;
		}
		.name {
			display: block;
		}
	}
}