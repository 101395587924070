.callout {
	max-width: 800px;
	background-image: map-get($gradients, 'brand');
	text-align: center;
	padding: 30px;
	border-radius: 0 0 $border-radius;
	margin: 0 auto $content-section-spacing-small;
	.text {
		font-family: $alt-font;
		font-size: rem-calc(20);
		font-weight: $alt-bold;
		color: $white;
		line-height: 1.25;
		margin-bottom: 12px;
	}
	.button {
		margin-top: 8px;
	}
}

@include breakpoint(medium) {
	.callout {
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text {
			padding-right: 15px;
			margin: 0;
		}
		.button {
			flex-shrink: 0;
			margin-top: 0;
		}
	}
}

@include breakpoint(large) {
	.callout {
		margin-bottom: $content-section-spacing;
		.text {
			font-size: rem-calc(25);
			line-height: 1.2;
		}
	}
}

// Callout with heading and text
.callout-stacked {
	border-radius: 0 0 17px 0;
	background-image: map-get($gradients, 'brand');
	color: $white;
	padding: 40px;
	margin-bottom: $base-spacing;
	.heading {
		color: $white;
		margin-bottom: 8px;
	}
}

//Callout media object
.callout-media-object {
	max-width: 467px;
	background-image: linear-gradient(135deg, #006d79 0%, #19b0a3 52%, #006d79 100%);
	border-radius: 0 0 $border-radius;
	margin: 0 auto $content-section-spacing-small;
	box-shadow: 0 22px 44px rgba(0, 0, 0, 0.31), 0 0 4px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	.text {
		color: $white;
		padding: 30px;
		.heading {
			color: $white;
			margin-bottom: 12px;
		}
	}
	.media {
		background-image: url('https://portapros.imgix.net/callouts/bg_gradient_callout_image.jp?auto=formatg');
		background-size: cover;
		background-position: center center;
		height: 280px;
		img {
			margin: 0;
		}
	}
}

.callout-media-object.narrow-variant {
	margin: 50px auto;
	.heading {
		font-size: rem-calc(28);
		margin-bottom: 20px;
	}
}

.callout-media-object .media {
	&.bg-emergency-truck {background-image: url('https://portapros.imgix.net/callouts/bg_emergency_truck.jpg?auto=format');}
	&.bg-team {background-image: url('https://portapros.imgix.net/callouts/bg_carl_arriola.jpg?auto=format');}
	&.bg-team-member-working {background-image: url('https://portapros.imgix.net/callouts/bg_team_member_working.jpg?auto=format');}
	&.bg-portapros-logo {background-image: url('https://portapros.imgix.net/callouts/bg_portapros_logo_gradient.jpg?auto=format');}
	&.bg-handwash-sanitize {background-image: url('https://portapros.imgix.net/callouts/bg_handwash_versus_sanitizing.jpg?auto=format');}
	&.bg-happy-tailgaters {background-image: url('https://portapros.imgix.net/callouts/happy_tailgaters.jpg?auto=format');}
	&.bg-employee-leaning-toilet {background-image: url('https://portapros.imgix.net/callouts/bg_employee_leaning_toilet.jpg?auto=format&w=468&q=50');}
	&.bg-scentsy-row-toilets {background-image: url('https://portapros.imgix.net/callouts/bg_scentsy_row_of_toilets.jpg?auto=format&w=468&q=50');}
}

@include breakpoint(large) {
	.callout-media-object {
		max-width: 1080px;
		background-image: linear-gradient(148deg, #006d79 0%, #19b0a3 100%);
		display: grid;
		grid-template-columns: 1fr 415px;
		align-items: center;
		margin: 0 auto $content-section-spacing;
		.text {
			padding-right: 80px;
		}
		.media {
			height: 100%;
			background-image: url('https://portapros.imgix.net/callouts/bg_gradient_callout_image_lrg.jp?auto=formatg');
			min-height: 344px;
		}
	}
	.callout-media-object .media {
		&.bg-emergency-truck {background-image: url('https://portapros.imgix.net/callouts/bg_emergency_truck_lrg.jpg?auto=format');}
		&.bg-team {background-image: url('https://portapros.imgix.net/callouts/bg_carl_arriola_lrg.jpg?auto=format');}
		&.bg-team-member-working {background-image: url('https://portapros.imgix.net/callouts/bg_team_member_working_lrg.jpg?auto=format');}
		&.bg-portapros-logo {background-image: url('https://portapros.imgix.net/callouts/bg_portapros_logo_gradient_lrg.jpg?auto=format');}
		&.bg-handwash-sanitize {background-image: url('https://portapros.imgix.net/callouts/bg_handwash_versus_sanitizing_lrg.jpg?auto=format');}
		&.bg-happy-tailgaters {background-image: url('https://portapros.imgix.net/callouts/happy_tailgaters_lrg.jpg?auto=format');}
		&.bg-employee-leaning-toilet {background-image: url('https://portapros.imgix.net/callouts/bg_employee_leaning_toilet.jpg?auto=format&w=414&q=50');}
		&.bg-scentsy-row-toilets {background-image: url('https://portapros.imgix.net/callouts/bg_scentsy_row_of_toilets.jpg?auto=format&w=414&q=50');}
	}
	.callout-media-object.narrow-variant {
		.text {
			padding-right: 30px;
		}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.callout-media-object .media {
		background-image: url('https://portapros.imgix.net/callouts/bg_gradient_callout_image@2x.jpg?auto=format&q=20');
		&.bg-emergency-truck {background-image: url('https://portapros.imgix.net/callouts/bg_emergency_truck@2x.jpg?auto=format&q=20');}
		&.bg-team {background-image: url('https://portapros.imgix.net/callouts/bg_carl_arriola@2x.jpg?auto=format&q=20');}
		&.bg-team-member-working {background-image: url('https://portapros.imgix.net/callouts/bg_team_member_working@2x.jpg?auto=format&q=20');}
		&.bg-portapros-logo {background-image: url('https://portapros.imgix.net/callouts/bg_portapros_logo_gradient@2x.jpg?auto=format&q=20');}
		&.bg-handwash-sanitize {background-image: url('https://portapros.imgix.net/callouts/bg_handwash_versus_sanitizing@2x.jpg?auto=format&q=20');}
		&.bg-happy-tailgaters {background-image: url('https://portapros.imgix.net/callouts/happy_tailgaters@2x.jpg?auto=format&q=20');}
		&.bg-employee-leaning-toilet {background-image: url('https://portapros.imgix.net/callouts/bg_employee_leaning_toilet.jpg?auto=format&w=468&dpr=2&q=50');}
		&.bg-scentsy-row-toilets {background-image: url('https://portapros.imgix.net/callouts/bg_scentsy_row_of_toilets.jpg?auto=format&w=468&dpr=2&q=40');}
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	@include breakpoint(large) {
		.callout-media-object .media {
			background-image: url('https://portapros.imgix.net/callouts/bg_gradient_callout_image_lrg@2x.jpg?auto=format&q=20');
			&.bg-emergency-truck {background-image: url('https://portapros.imgix.net/callouts/bg_emergency_truck_lrg@2x.jpg?auto=format&q=20');}
			&.bg-team {background-image: url('https://portapros.imgix.net/callouts/bg_carl_arriola_lrg@2x.jpg?auto=format&q=20');}
			&.bg-team-member-working {background-image: url('https://portapros.imgix.net/callouts/bg_team_member_working_lrg@2x.jpg?auto=format&q=20');}
			&.bg-portapros-logo {background-image: url('https://portapros.imgix.net/callouts/bg_portapros_logo_gradient_lrg@2x.jpg?auto=format&q=20');}
			&.bg-handwash-sanitize {background-image: url('https://portapros.imgix.net/callouts/bg_handwash_versus_sanitizing_lrg@2x.jpg?auto=format&q=20');}
			&.bg-happy-tailgaters {background-image: url('https://portapros.imgix.net/callouts/happy_tailgaters_lrg@2x.jpg?auto=format&q=20');}
			&.bg-employee-leaning-toilet {background-image: url('https://portapros.imgix.net/callouts/bg_employee_leaning_toilet.jpg?auto=format&w=414&dpr=2&q=40');}
			&.bg-scentsy-row-toilets {background-image: url('https://portapros.imgix.net/callouts/bg_scentsy_row_of_toilets.jpg?auto=format&w=414&dpr=2&q=40');}
		}
	}
}

//Text callout
.callout-text {
	max-width: 700px;
	background-color: $brand;
	color: $white;
	border-radius: 0 0 $border-radius;
	padding: 20px;
	margin: 0 auto;
	&.full-width {
		max-width: 100%;
	}
	> *:last-child {
		margin-bottom: 0;
	}
	.heading {
		color: $white;
		margin-bottom: 12px;
	}
	ul {
		margin-top: 0;
	}
	+ .callout-text {
		margin-top: $intro-section-spacing-small;
	}
	a {
		color: $brand-accent;
		&:hover, &:focus {
			color: $white;
		}
	}
}

.callout-text-footer {
	border-top: solid 1px $brand-alt-accent;
	padding-top: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	img {
		margin: 0 15px 0 0;
	}
	p {
		font-weight: $main-bold;
		line-height: 1.22;
		margin: 0;
	}
}

// Icon group callout
.icon-group-callout {
	background-color: $extra-light-gray;
	max-width: 550px;
	border-radius: 5px;
	padding: 20px;
	margin: 0 auto $base-spacing;
	.heading {
		@include font-size(default);
		color: $brand;
		line-height: 1.2;
		margin-bottom: 10px;
	}
}

.icon-group-callout.inline-group {
	background-color: transparent;
	max-width: 100%;
	border-radius: 0;
	padding: 0;
	margin: 0 auto $base-spacing;
	.icon-text-group.inline {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		.text {
			font-size: 1rem;
		}
		.icon {
			width: 20px;
			display: flex;
		}
		.icon-item {
			width: auto;
			gap: 10px;
			margin: 0;
		}
	}
}

.icon-text-group {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	gap: 30px;
	.icon-item {
		background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
		max-width: 150px;
		width: 100%;
		text-align: center;
		padding: 20px;
		border-radius: 10px;
		margin: 0 auto;
	}
	.icon {
		height: 78px;
		color: $light-blue;
		margin-bottom: 10px;
		img, svg {
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
	.text {
		font-size: rem-calc(17);
		font-weight: $alt-bold;
		color: $white;
		line-height: 1.29;
		margin: 0;
	}
}

.icon-text-group.inline {
	grid-template-columns: 1fr;
	gap: 10px;
	.icon {
		width: 30px;
		height: auto;
		text-align: left;
		flex-shrink: 0;
		margin: 0;
	}
	.icon-item {
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
	}
	.text {
		text-align: left;
	}
}

@include breakpoint(1030px) {
	.icon-group-callout {
		padding: 20px 30px;
		.heading {
			@include font-size(default-desktop);
			line-height: 1.2;
		}
	}

	.icon-text-group {
		.text {
			font-size: rem-calc(21);
			line-height: 1.43;
		}
	}
}

// Info box callouts
.callout-info-box {
	background: rgba(0, 121, 85, 0.1);
	max-width: 335px;
	padding: 20px;
	border-radius: 4px;
	margin: 0 auto 30px;
	> *:last-child {
		margin-bottom: 0;
	}
	> *:first-child {
		margin-bottom: 10px;
	}
	ul {
		margin-top: 10px;
	}
}

.callout-info-box-highlight {
	font-size: rem-calc(13);
	line-height: 1.28;
	padding: 10px 20px;
}

.callout-info-box.red {
	background: rgba(186, 84, 84, 0.1);
	.callout-info-box-highlight {
		background: rgba(186, 84, 84, 0.1);
	}
	a {
		color: $brand;
		&:hover, &:focus {
			color: $darker-gray;
		}
	}
}

@include breakpoint(medium) {
	.callout-info-box-group {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-column-gap: 30px;
		&.wide {
			.callout-info-box {
				max-width: 425px;
			}
		}
		&.extra-wide {
			grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
			.callout-info-box {
				width: 100%;
				max-width: 650px;
			}
		}
	}
}


// Employee callout with photo, title and contact info
.employee-callout {
	.image {
		position: relative;
		img {
			width: auto;
			margin: 0 0 0 auto;
			display: block;
		}
		p {
			font-family: $heading-font;
			font-size: rem-calc(25);
			font-weight: $heading-bold;
			color: $white;
			line-height: 1.2;
			position: absolute;
			left: 0;
			bottom: 0;
			margin: 0 0 10px;
		}
		.title {
			font-size: rem-calc(17);
			display: block;
		}
	}
	.text {
		background-color: $white;
		padding: 20px;
		border-radius: 0 0 30px 0;
		.highlight-text {
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $brand;
			line-height: 1.3;
			margin-bottom: 14px;
		}
		.contact-options {
			p {
				margin-bottom: 4px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include breakpoint(1100px) {
	.employee-callout {
		.text {
			.contact-options {
				p {
					margin: 0 20px 0 0;
					display: inline-block;
				}
			}
		}
	}
}

// Callout with media overlap on top
.callout-media-overlap-container {
	container-type: inline-size;
}

.callout-media-overlap {
	max-width: 780px;
	background: $brand;
	color: $white;
	text-align: center;
	padding: 20px 20px 0;
	margin: 0 auto $base-spacing;
	border-radius: 5px;
	.heading {
		font-size: rem-calc(24);
		color: $white;
		line-height: 1.16;
		margin-bottom: 10px;
	}
	img {
		margin: 0;
	}
	.callout-media-overlap-text {
		margin-bottom: 20px;
	}
}

@container (min-width: 34.375em) {
	.callout-media-overlap {
		padding-right: 30px;
		margin-top: 55px;
		display: flex;
		gap: 30px;
		.callout-media-overlap-text {
			text-align: left;
			margin-bottom: 30px;
			flex-basis: 460px;
		}
		.callout-media-overlap-media {
			flex-basis: 212px;
			align-self: flex-end;
			img {
				margin-top: -75px;
			}
		}
	}
}

@container (min-width: 43.75em) {
	.callout-media-overlap {
		.heading {
			font-size: rem-calc(36);
			line-height: 1.11;
		}
	}
}

// Callout with icon in left column
.callout-icon-object {
	background-color: $extra-light-gray;
	padding: 20px;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: $base-spacing;
	&.extra-space {
		margin-bottom: $base-spacing * 2;
	}
	a {
		color: $dark-green;
		&:hover, &:focus {
			color: $brand;
		}
	}
	img {
		margin: 0;
	}
	p {
		margin: 0;
	}
}

@include breakpoint(500px) {
	.callout-icon-object {
		flex-wrap: nowrap;
	}
}

// Callout warning
.callout-warning {
	background: rgba(186, 84, 84, 0.10);
	padding: 10px;
	.warning-highlight {
		font-weight: $main-bold;
		color: $red;
	}
	p {
		line-height: 1.222;
		margin: 0;
	}
	a {
		font-weight: $main-bold;
		color: $brand;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
}

// Inline callout
.inline-callout {
	background: $brand;
	color: $white;
	text-align: center;
	padding: 20px 30px;
	border-radius: 5px;
	p {
		margin: 0;
	}
	a {
		color: $white;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
}

// Callout banner
.callout-banner {
	max-width: 860px;
	background: $extra-light-gray;
	border-radius: 5px;
	padding: 20px;
	margin: 0 auto $base-spacing;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 20px;
	p {
		margin-bottom: 0;
	}
	img {
		margin-bottom: 0;
	}
	a {
		font-weight: $main-bold;
	}
	.image {
		flex-basis: 40px;
		flex-shrink: 1;
	}
	.content {
		flex-shrink: 2;
	}
	.highlight {
		color: $dark-green;
		font-weight: $main-bold;
	}
}

.callout-banner.vertical {
	text-align: center;
	flex-direction: column;
	gap: 6px;
	justify-content: center;
	align-items: center;
	> * {
		margin-bottom: 0;
	}
}

.callout-banner.alt {
	p {
		color: $dark-gray;
	}
}

@include breakpoint(500px) {
	.callout-banner {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 20px;
		p {
			margin-bottom: 0;
		}
		img {
			margin-bottom: 0;
		}
		a {
			font-weight: $main-bold;
		}
		.image {
			flex-basis: 40px;
			flex-shrink: 1;
		}
		.content {
			flex-shrink: 2;
		}
	}
}