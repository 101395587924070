.grid-2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px 30px
}

@include breakpoint(large) {
	.grid-2 {
		grid-gap: 50px 115px;
	}
}

.grid-col-2 {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	&.gap-30 {
		gap: 30px;
	}
	&.gap-40 {
		gap: 40px;
	}
	> div {
		max-width: 640px;
		width: 100%;
		margin: 0 auto;
	}
	@include breakpoint(830px) {
		grid-template-columns: 1fr 1fr;
		gap: 50px;
		&.extra-space {
			gap: 70px;
		}
	}
}

.grid-col-3 {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	> div {
		max-width: 500px;
		margin: 0 auto;
	}
}


.grid-3 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 50px 40px;
}

@include breakpoint(800px) {
	.grid-wide-left {
		display: grid;
		grid-template-columns: 6fr 4fr;
		gap: 30px;
	}
}

@include breakpoint(1250px) {
	.grid-wide-left {
		gap: 56px;
	}
}
