/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $off-white;
	font-family: $main-font;
	@include font-size(default);
	font-weight: $main-normal;
	color: $main-color;
	@include breakpoint(large) {
		@include font-size(default-desktop);
	}
}
p {
	font-size: 1em;
	margin: 0 0 24px;
	&.large {
		@include font-size(large);
		margin-bottom: 18px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 8px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a {
	color: $link-base;
	transition: color .3s;
	text-decoration: none;
	&:hover,
	&:focus-visible {
		color: $link-active;
		text-decoration: none;
	}
}

a.brand {
	color: $link-active;
	&:hover,
	&:focus-visible {
		color: $link-base;
	}
}

a.underline {
	color: $link-active;
	text-decoration: underline;
	&:hover,
	&:focus-visible {
		color: $link-base;
	}
}

hr {
	margin: 20px 0;
}

.inner {
	@include xy-grid-container;
}

.slight-narrow-content {
	max-width: 1030px;
	margin-left: auto;
	margin-right: auto;
}

.narrow-content {
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
}

.extra-narrow-content {
	max-width: 635px;
	margin-left: auto;
	margin-right: auto;
}
/* @end */