.tabs {
	overflow: hidden;
	.tab-list {
		margin: 0 0 54px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px 38px;
		li {
			padding: 0;
			margin: 0;
			&::before {
				display: none;
			}
		}
		img {
			display: none;
		}
		button {
			font-family: $alt-font;
			font-size: rem-calc(14);
			font-weight: $alt-bold;
			color: $dark-green;
			line-height: 1;
			padding: 7px 10px 7px;
			border-radius: 5px;
			display: flex;
			align-items: baseline;
			text-align: left;
			cursor: pointer;
			transition: background .3s, color .3s;
			&.active,
			&:hover,
			&:focus {
				background: $dark-green;
				color: $white;
			}
		}
	}
	.tabs-callout-box {
		background: $extra-light-gray;
		border-radius: 5px;
		padding: 20px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.tab-panel-grid {
		width: 100%;
		.image {
			text-align: center;
			margin-bottom: 30px;
			img {
				margin: 0;
			}
		}
	}
}

@include breakpoint(500px) {
	.tabs {
		.tab-list {
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		}
	}
}

@include breakpoint(850px) {
	.tabs {
		.tab-list {
			display: flex;
			flex-wrap: wrap;
			gap: 20px 10px;
			justify-content: center;
			button {
				padding: 7px 15px;
				display: flex;
				align-items: center;
				gap: 5px;
			}
			img {
				display: block;
				margin: 0;
			}
		}
		.tab-panel-grid {
			display: flex;
			align-items: flex-start;
			gap: 40px;
			.image {
				flex-shrink: 1;
			}
			.text {
				flex-shrink: 3;
			}
		}
	}
}

@include breakpoint(1000px) {
	.tabs {
		.tab-list {
			button {
				font-size: rem-calc(18);
			}
		}
		.tab-panel-grid {
			.image {
				flex-shrink: 0;
			}
		}
	}
}

// Rental type toggle for short and long term options on product pages
.rental-type-toggle {
	.rental-type-toggle-list {
		background: $extra-light-gray;
		padding: 5px 7px;
		border-radius: 4px;
		display: grid;
		grid-template-columns: 1fr 2px 1fr;
		align-items: center;
		justify-content: space-between;
		gap: 20px 5px;
		&::after {
			content: '';
			background-color: #E1E1E1;
			width: 2px;
			height: 90%;
			display: block;
			grid-column: 2;
			grid-row: 1;
		}
		li {
			flex-basis: calc(50% - 5px);
			padding: 0;
			margin: 0;
			&::before {
				display: none;
			}
			&:last-child::after {
				display: none;
			}
		}
		button {
			width: 100%;
			font-size: rem-calc(18);
			color: $dark-green;
			padding: 11px 22px;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color .2s;
			&.active,
			&:hover,
			&:focus {
				background-color: $dark-green;
				color: $white;
				box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
				span {
					color: $white;
				}
			}
			span {
				color: $dark-gray;
				margin-top: 5px;
				display: block;
			}
		}
	}
}