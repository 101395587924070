.mapbox-wrapper {
	position: relative;
	box-shadow: 0 0 70px rgba(0, 0, 0, 0.2);
	.mapbox-embed {
		height: auto;
		padding-bottom: 45%;
		position: relative;
		overflow: hidden;
	}
	.mapboxgl-map {
		overflow: visible;
	}
}

.service-map-section {
	max-width: 600px;
	margin: 0 auto;
}
.service-map {
	margin-bottom: 50px;
	img {
		margin: 0;
	}
}
.service-areas {
	ul {
		margin-top: 10px;
		margin-bottom: 30px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-column-gap: 40px;
	}
	p {
		margin-bottom: 10px;
	}
}

@include breakpoint(800px) {
	.service-map-section {
		max-width: 100%;
	}
	.service-map-wrapper {
		display: flex;
		gap: 30px;
	}
	.service-map {
		flex-basis: 50%;
		margin-bottom: 0;
		img {
			margin-bottom: 40px;
		}
	}
	.service-areas {
		flex-basis: 50%;
	}
	.service-map-wrapper.stacked {
		display: block;
	}
}