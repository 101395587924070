.portapros-difference-section {
	background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
	color: $white;
	padding: 75px 0 0;
	h2 {
		color: $white;
	}
	h3 {
		color: $white;
		padding-bottom: 3px;
		border-bottom: solid 3px $brand-accent;
		margin-bottom: 30px;
	}
	h4 {
		color: $white;
	}
	.core-values-list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		gap: 30px;
	}
	.core-values-list-item {
		h4 {
			display: flex;
			align-items: center;
			gap: 10px;
			.icon {
				width: auto;
				height: 20px;
				color: $brand-accent;
				display: flex;
			}
			svg {
				width: auto;
				height: 100%;
			}
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.media {
		margin-top: 30px;
		text-align: center;
	}
	.media-wrapper {
		position: relative;
		display: inline-block;
		img {
			margin: 0;
		}
		.media-description {
			background: $brand;
			color: $white;
			line-height: 1.2;
			border-radius: 0 0 17px;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
			padding: 10px 20px 13px;
			display: inline-block;
			position: absolute;
			bottom: 40px;
			right: 40px;
			p {
				margin: 0;
			}
			span {
				line-height: 1.1;
				display: block;
			}
		}
	}
}

@include breakpoint(800px) {
	.portapros-difference-section {
		padding-bottom: 108px;
		.inner {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 30px;
		}
		.text {
			flex-basis: 577px;
		}
		.media {
			margin-top: 0;
			flex-basis: 544px;
		}
		.media-wrapper {
			border-bottom: solid 9px $brand-accent;
		}
	}
}

@include breakpoint(1100px) {
	.portapros-difference-section {
		.text {
			flex-basis: 720px;
		}
	}
}