.page-heading {
	height: 240px;
	background-image: url('/images/cssimg/heading_texture.svg'), linear-gradient(180deg, #fbfaf8 0%, #f2eee5 100%);
	padding-top: 60px;
	box-shadow: inset 0 1px 13px rgba(0, 0, 0, 0.05);
	.inner {
		height: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		align-items: flex-end;
	}
	h1 {
		background-color: $brand;
		color: $white;
		padding: 16px 20px 24px;
		border-radius: 0 0 $border-radius;
		display: inline-block;
		span {
			font-family: $main-font;
			font-weight: $main-normal;
			font-size: rem-calc(18);
			margin-top: 20px;
			display: block;
		}
	}
}

@include breakpoint(large) {
	.page-heading {
		height: 302px;
	}
}