.media-object {
	padding-bottom: $content-section-spacing-small;
	.media {
		text-align: center;
		margin-bottom: 24px;
		img {
			margin: 0;
		}
	}
	.subheading {
		font-size: rem-calc(17);
		line-height: 1.29;
		margin-bottom: 6px;
	}
	ul {
		margin-top: 0;
	}
	.list-background {
		max-width: 400px;
		li {
			background-color: $extra-light-gray;
			padding: 5px 10px;
			margin: 0;
			&::before {
				display: none;
			}
			&:nth-child(even) {
				background-color: $white;
			}
		}
		small {
			line-height: 1.2;
			display: inline-block;
		}
	}
}

@include breakpoint(850px) {
	.media-object {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.media {
			padding: 60px 30px 0;
		}
	}
}

@include breakpoint(large) {
	.media-object {
		grid-template-columns: 445px 1fr;
		padding-bottom: $content-section-spacing;
		.subheading {
			font-size: rem-calc(21);
			line-height: 1.43;
			margin-bottom: 10px;
		}
		.split-list {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
			grid-gap: 12px 33px;
			justify-content: space-between;
			li {
				margin-bottom: 0;
			}
		}
		.list-group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 30px;
		}
	}
	.media-object.wide-media {
		grid-template-columns: 1fr 1fr;
		.split-list {
			display: block;
		}
		.list-group {
			display: block;
		}
	}
}

@include breakpoint(1150px) {
	.media-object.wide-media {
		.split-list {
			display: grid;
		}
		.list-group {
			display: grid;
		}
	}
}

// Media object with scaling over image
.media-object-cover {
	margin-bottom: 50px;
	.media {
		text-align: center;
		margin-bottom: 20px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			margin: 0;
		}
	}
	p {
		margin: 0;
	}
}

@include breakpoint(640px) {
	.media-object-cover {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		.media {
			align-self: stretch;
			margin-bottom: 0;
		}
		.text {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 30px;
		}
	}
	.media-object-cover.flip {
		.media {
			order: 1;
		}
		.text {
			padding-left: 0;
			padding-right: 30px;
		}
	}
}

@include breakpoint(1100px) {
	.media-object-cover .text {
		padding-left: 45px;
	}
	.media-object-cover.flip .text {
		padding-right: 45px;
	}
}

// Media object highlight
.media-object-highlight {
	max-width: 450px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 30px;
	.media {
		text-align: center;
		img {
			margin: 0;
		}
	}
}

@include breakpoint(600px) {
	.media-object-highlight {
		max-width: 100%;
		flex-direction: row;
		align-items: center;
		.media {
			flex-basis: 546px;
		}
		.text {
			flex-basis: 600px;
		}
	}
}

@include breakpoint(1100px) {
	.media-object-highlight {
		gap: 110px;
	}
}

// Media object with small image
.media-object-small-container {
	container-name: media-object-small-container;
	container-type: inline-size;
	display: grid;
	gap: 45px;
}

.media-object-small {
	.heading {
		margin-bottom: 10px;
	}
	.text {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@container media-object-small-container (min-width: 450px) {
	.media-object-small {
		display: flex;
		gap: 15px;
		.media {
			flex-basis: 106px;
		}
		.text {
			flex-basis: 470px;
		}
	}
}


// Media object callout
.media-object-callout {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 30px;
	.media {
		text-align: center;
		img {
			margin: 0;
			&.rounded {
				border-radius: 13px;
			}
		}
	}
	.text {
		> *:last-child {
			margin-bottom: 0;
		}
	}
	h2 {
		margin-bottom: $base-spacing;
	}
	a {
		font-weight: $main-bold;
	}
}

@include breakpoint(850px) {
	.media-object-callout {
		max-width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.media {
			flex-basis: 505px;
		}
		.text {
			flex-basis: 755px;
		}
	}
}

@include breakpoint(1300px) {
	.media-object-callout {
		gap: 40px;
	}
}

// Dark brand bg with white text
.media-object-callout.dark-bg {
	background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
	color: $white;
	border-radius: 5px;
	padding: 20px;
	h2 {
		color: $brand-accent;
		.subhead {
			color: $white;
		}
	}
	a {
		color: $white;
		transiton: text-decoration-color .3s;
		&:hover, &:focus {
			text-decoration-color: $brand-accent;;
		}
	}
}

@include breakpoint(1300px) {
	.media-object-callout.dark-bg {
		padding: 30px;
	}
}

// Narrow container
@include breakpoint(850px) {
	.media-object-callout.narrow {
		max-width: 900px;
		.media {
			flex-basis: 388px;
		}
		.text {
			flex-basis: 392px;
		}
	}
}

@include breakpoint(1300px) {
	.media-object-callout.narrow {
		padding: 40px;
	}
}

// Media object alternating pattern
.media-object-alt-container {
	margin-bottom: $base-spacing;
	display: grid;
	grid-template-columns: 1fr;
	gap: 54px;
}

@include breakpoint(700px) {
	.media-object-alt-container {
		> *:nth-child(even) {
			.media {
				order: 2;
			}
		}
	}
}

.media-object-alt {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	.media {
		img {
			border: solid 6px $white;
			box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
			margin: 0;
		}
	}
	.text {
		 > *:last-child {
			margin-bottom: 0;
		 }
	}
}

@include breakpoint(700px) {
	.media-object-alt {
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		.media {
			flex-basis: 636px;
		}
		.text {
			flex-basis: 616px;
		}
	}
}