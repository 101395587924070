/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: $content-section-spacing-small 0 ($content-section-spacing-small - 24px);
	&.short-top {
		padding-top: 54px;
	}
}

.content-aside {
	display: grid;
	grid-row-gap: 50px;
}

@include breakpoint(medium) {
	.content-aside {
		.aside {
			.callout-text {
				max-width: 100%;
				ul {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}
	}
}

@include breakpoint(large) {
	.page-content {
		padding: $content-section-spacing 0 ($content-section-spacing - 24px);
	}
	.content-aside {
		grid-template-columns: 2fr 1fr;
		grid-gap: 0 72px;
		&.tight {
			gap: 30px;
		}
		.aside {
			.callout-text {
				ul {
					display: block;;
				}
			}
		}
	}
}