.double-bg-section {
    .inner {
        padding: 0;
    }
    .col {
        padding: 30px;
    }
    .col:first-child {
        background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
        color: $white;
        h2 {
            color: $brand-accent;
        }
    }
    .col:last-child {
        background: $extra-light-gray;
    }
    form {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 6px 30px;
        .field-full {
            grid-column: 1 / -1;
        }
        textarea {
            min-height: 125px;
        }
    }
}

@include breakpoint(850px) {
    .double-bg-section {
        background: linear-gradient(to right, #004D58 0%, #00353C 50%,  $extra-light-gray 50%,  $extra-light-gray 100%);
        .col:first-child,
        .col:last-child {
            background: none;
        }
        .inner {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-left: 1.875rem;
            padding-right: 1.875rem;
        }
        .col {
            padding-left: 0;
            padding-right: 0;
            padding-top: 54px;
            padding-bottom: 54px;
        }
        .col:first-child {
            padding-right: 50px;
        }
        .col:last-child {
            padding-left: 50px;
        }
    }
}

@include breakpoint(1100px) {
    .double-bg-section {
        .col {
            padding-top: 108px;
            padding-bottom: 108px;
        }
        .col:first-child {
            padding-right: 50px;
        }
        .col:last-child {
            padding-left: 50px;
        }
    }
}