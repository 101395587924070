.heading-aside {
	background: url('https://portapros.imgix.net/heading-aside/man_toilet_watermark.png?auto=format&w=355') $light-tan no-repeat left bottom;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://portapros.imgix.net/heading-aside/man_toilet_watermark.png?auto=format&w=355&dpr=2&q=20');
		background-size: 355px 343px;
	}
	h2 {
		font-size: rem-calc(40);
		color: $brand;
		line-height: 1;
		margin-bottom: 25px;
	}
	h3 {
		font-family: $main-font;
		font-size: rem-calc(24);
		font-weight: $main-bold;
		color: $brand;
		line-height: 1.58;
		margin-bottom: 14px;
	}
}

@include breakpoint(800px) {
	.heading-aside {
		h2 {
			font-size: rem-calc(80);
		}
		h3 {
			font-size: rem-calc(36);
			color: $brand;
			line-height: 1.11;
			margin-bottom: 30px;
		}
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.heading {
			padding-right: 70px;
		}
	}
}

@include breakpoint(1200px) {
	.heading-aside {
		background-image: url('https://portapros.imgix.net/heading-aside/man_toilet_watermark.png?auto=format&w=600');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://portapros.imgix.net/heading-aside/man_toilet_watermark.png?auto=format&w=600&dpr=2&q=20');
			background-size: 600px 580px;
		}
	}
}