/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $dark-green;
	font-family: $alt-font;
	font-weight: $alt-bold;
	font-size: rem-calc(18);
	color: $white;
	text-shadow: $text-shadow-brand;
	line-height: 1.2;
	padding: 9px 24px 13px;
	border: 3px solid $white;
	border-radius: 0 0 $border-radius;
	display: inline-block;
	box-shadow: $box-shadow-subtle;
	transition: .3s background-color;
	&:hover,
	&:focus {
		background-color: $brand;
		color: $white;
		text-decoration: none;
		cursor: pointer;
	}
	&:disabled {
		background-color:  #666666;
		color:  #CCCCCC;
		border-color:  #CCCCCC;
		cursor: wait;
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 6px 13px 10px;
}
.button.medium {
	font-size: rem-calc(20);
	padding: 14px 20px 16px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 16px 23px 20px;
}
.button.short {
	padding-top: 7px;
	padding-bottom: 9px;
}
.button.tiny {
	font-size: rem-calc(13);
	padding: 3px 8px 5px;
}
.button.alt {
	background: transparent;
	border: solid 3px $brand;
	color: $brand;
	text-shadow: none;
	box-shadow: none;
	transition: border-color .3s, color .3s;
	&:hover,
	&:focus {
		color: lighten($brand, 12%);
		border-color: lighten($brand, 12%);
	}
}
.button.alt.light {
	color: $white;
	border-color: $white;
	&:hover,
	&:focus {
		color: $brand-accent;
		border-color: $brand-accent;
	}
}

.button.icon-button {
	display: inline-flex;
	align-items: center;
	.icon {
		margin-right: 10px;
		line-height: 1;
	}
}
.button.white {
	background-color: $white;
	color: $brand;
	text-shadow: none;
	border-color: $brand;
	transition: background-color .3s, color .3s;
	&:hover, &:focus {
		background-color: $brand-accent;
		color: $white;
	}
}