/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/
form {
	label {
		font-size: rem-calc(12);
		font-weight: $main-bold;
		color: $white;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	}
	fieldset .checkbox-list {
		margin-bottom: 10px;
		.checkbox {
			margin-right: 10px;
			margin-bottom: 10px;
		}
		label {
			font-size: rem-calc(16);
			line-height: 1;
			text-transform: none;
			align-items: center;
		}
	}
}

[type="color"], [type="date"], [type="datetime-local"],
[type="datetime"], [type="email"], [type="month"],
[type="number"], [type="password"], [type="search"],
[type="tel"], [type="text"], [type="time"],
[type="url"], [type="week"], textarea {
	height: 40px;
	box-shadow: none;
	border: none;
}
input::-webkit-date-and-time-value {
  text-align: left;
}

.contained-form {
	background-color: $brand;
	color: $white;
	padding: 30px;
	margin-bottom: 64px;
	border-radius: 0 0 $border-radius 0;
	&.base-space {
		margin-bottom: $base-spacing;
	}
	h2 {
		font-size: rem-calc(25);
		color: $brand-accent;
		margin-bottom: 10px;
	}
	legend {
		font-size: rem-calc(12);
		font-weight: $main-bold;
		color: $white;
		line-height: 1.2;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	}
	fieldset {
		display: flex;
		flex-wrap: wrap;
		label {
			font-weight: $main-normal;
			line-height: 1.2;
			display: flex;
			align-items: flex-start;
			margin: 0 12px 5px 0;
			&:last-child {
				margin-right: 0;
			}
			input {
				margin: 0 5px 0 0;
			}
		}
	}
	p {
		font-size: rem-calc(16);
		line-height: 1.2;
	}
	.highlight {
		font-weight: $main-bold;
		color: $brand-accent;
	}
	.submit {
		text-align: right;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	.button {
		margin-top: 16px;
	}
}

@include breakpoint(medium) {
	.contained-form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-column-gap: 16px;
	}
}


#employment-application-form {
	display: block;
	.employment-history {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-column-gap: 40px;
		.field-full:last-child input[type="text"] {
			margin-bottom: 0;
		}
	}
	.field-group {
		display: grid;
		grid-column-gap: 40px;
		@include breakpoint(345px) {
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		}
		.field-full {
			grid-column: 1 / -1;
		}
		&.full {
			grid-column: 1 / -1;
		}
	}
	h3 {
		font-size: rem-calc(21);
		color: $yellow;
	}
	p {
		line-height: 1.5;
	}
	legend {
		font-size: rem-calc(14);
		text-transform: none;
		text-shadow: none;
	}
	label {
		font-size: rem-calc(14);
		text-transform: none;
		line-height: 1.2;
		text-shadow: none;
	}
	input[type="text"],
	input[type="email"],
	input[type="date"] {
		margin-top: 6px;
		margin-bottom: 30px;
	}
	fieldset {
		margin-bottom: 30px;
		+ p {
			margin-top: -20px;
		}
		legend {
			line-height: 1.3;
		}
		label {
			font-weight: $main-normal;
			line-height: 1.1;
		}
		&.stacked-options {
			margin-bottom: 20px;
		}
	}
	hr {
		margin: 40px 0;
		border-color: $white;
	}
}

// Form media object
.form-media {
	background: linear-gradient(315deg, #19B0A3 0%, #006D79 100%);
	padding: 30px 0;
	h2 {
		font-size: rem-calc(25);
		color: $white;
		line-height: 1.11;
		margin-bottom: 14px;
	}
	textarea {
		height: 100px;
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.media {
		margin-bottom: 30px;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 586px;
	}
	.form {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 636px;
	}
}

@include breakpoint(700px) {
	.form-media {
		.inner {
			flex-wrap: nowrap;
		}
		.media {
			padding-right: 40px;
			margin-bottom: 0;
		}
		.form {
			padding-left: 40px;
		}
	}
}

@include breakpoint(800px) {
	.form-media {
		.field-group {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}
		.field-full {
			grid-column: 1 / -1;
		}
	}
}


// Recaptcha wrapper
.g-recaptcha {
	// min-height: 78px;
}


// Contained form callout
.form-callout-contained {
	border-radius: 17px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	.form-container {
		background: linear-gradient(180deg, #006D79 0%, #004B53 100%) $brand;
		color: $white;
		padding: 30px 0;
		h2 {
			color: $white;
		}
		form {
			max-width: 400px;
			margin: 0 auto;
		}
		a {
			color: $white;
			text-decoration: underline;
		}
		.recaptcha-field {
			margin-bottom: 20px;
		}
		.form-col-right {
			padding-bottom: 1px;
		}
	}
	.form-footer {
		background-color: $white;
		padding: 20px;
		p {
			margin: 0 0 15px;
		}
		a:not(.button) {
			color: $brand;
			&:hover, &:focus-visible {
				color: $brand-accent;
			}
		}
	}
}

@include breakpoint(medium) {
	.form-callout-contained {
		.form-footer {
			width: 100%;
			display: flex;
			gap: 30px;
			p {
				margin: 0;
			}
			.action {
				flex-shrink: 0;
			}
		}
	}
}

@include breakpoint(800px) {
	.form-callout-contained {
		.form-container {
			form {
				max-width: 100%;
			}
			.form-col-left {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 20px;
			}
		}
	}
}

@include breakpoint(large) {
	.form-callout-contained {
		.form-container {
			form {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px 30px;
			}
			.form-col-left {
				grid-column-gap: 30px;
			}
			.form-col-right .field-full {
				height: 100%;
				display: flex;
				flex-direction: column;
				textarea {
					height: 100%;
					margin-bottom: 0;
				}
			}
		}
		.form-footer {
			padding: 30px;
			p {
				font-size: rem-calc(20);
			}
		}
	}
}

// Form callout in container inline with text on the left
.form-inline-callout-contained {
	background-color: $brand;
	color: $white;
	padding: 30px;
	margin-bottom: 64px;
	border-radius: 0 0 $border-radius 0;
	&.base-space {
		margin-bottom: $base-spacing;
	}
	h2 {
		font-size: rem-calc(25);
		color: $brand-accent;
		margin-bottom: 10px;
	}
	legend {
		font-size: rem-calc(12);
		font-weight: $main-bold;
		color: $white;
		line-height: 1.2;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	}
	form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 0 20px;
	}
	p {
		font-size: rem-calc(18);
		line-height: 1.5;
	}
	.highlight {
		font-weight: $main-bold;
		color: $brand-accent;
	}
	.submit {
		text-align: right;
	}
	.field-full {
		grid-column: 1 / -1;
	}
	.button {
		margin-top: 16px;
	}
}

@include breakpoint(840px) {
	.form-inline-callout-contained {
		width: 100%;
		display: flex;
		gap: 30px;
		justify-content: space-between;
		.text {
			flex-basis: 550px;
		}
		.form {
			flex-basis: 430px;
		}
	}
}
