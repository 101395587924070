.faq-group {
	margin-bottom: 64px;
	&:last-child {
		margin-bottom: 0;
		.faq:last-child {
			margin-bottom: 0;
		}
	}
	h2 {
		margin-bottom: 32px;
	}
}
.faq {
	margin-bottom: 32px;
	h3 {
		color: $brand;
		margin-bottom: 12px;
	}
	p + ul {
		margin-top: -20px;
	}
	.mapbox-wrapper {
		margin-bottom: 24px;
	}
}