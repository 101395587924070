.border-highlight-items {
	display: grid;
	gap: 30px;
}

.border-highlight-item {
	padding-left: 10px;
	border-left: solid 4px $brand-accent;
	h3 {
		margin-bottom: 10px;
	}
	> *:last-child {
		margin-bottom: 0;
	}
}