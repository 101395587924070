.icon-text-grid-stacked {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	gap: 24px 30px;
	img {
		margin: 0 0 4px;
	}
	p {
		font-family: $alt-font;
		font-size: 1rem;
		font-weight: $alt-bold;
		line-height: 1.3;
		margin: 0;
	}
}