.media-object {
	padding-bottom: $content-section-spacing-small;
	.media {
		text-align: center;
		margin-bottom: 24px;
		img {
			margin: 0;
		}
	}
	.subheading {
		font-size: rem-calc(17);
		line-height: 1.29;
		margin-bottom: 6px;
	}
	ul {
		margin-top: 0;
	}
	.list-background {
		max-width: 400px;
		li {
			background-color: $light-tan;
			padding: 5px 10px;
			margin: 0;
			&::before {
				display: none;
			}
			&:nth-child(even) {
				background-color: $off-white;
			}
		}
		small {
			line-height: 1.2;
			display: inline-block;
		}
	}
}

@include breakpoint(850px) {
	.media-object {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.media {
			padding: 60px 30px 0;
		}
	}
}

@include breakpoint(large) {
	.media-object {
		grid-template-columns: 445px 1fr;
		padding-bottom: $content-section-spacing;
		.subheading {
			font-size: rem-calc(21);
			line-height: 1.43;
			margin-bottom: 10px;
		}
		.split-list {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
			grid-gap: 12px 33px;
			justify-content: space-between;
			li {
				margin-bottom: 0;
			}
		}
		.list-group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 30px;
		}
	}
	.media-object.wide-media {
		grid-template-columns: 1fr 1fr;
		.split-list {
			display: block;
		}
		.list-group {
			display: block;
		}
	}
}

@include breakpoint(1150px) {
	.media-object.wide-media {
		.split-list {
			display: grid;
		}
		.list-group {
			display: grid;
		}
	}
}

// Media object with scaling over image
.media-object-cover {
	margin-bottom: 50px;
	.media {
		text-align: center;
		margin-bottom: 20px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			margin: 0;
		}
	}
	p {
		margin: 0;
	}
}

@include breakpoint(640px) {
	.media-object-cover {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		.media {
			align-self: stretch;
			margin-bottom: 0;
		}
		.text {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 30px;
		}
	}
	.media-object-cover.flip {
		.media {
			order: 1;
		}
		.text {
			padding-left: 0;
			padding-right: 30px;
		}
	}
}

@include breakpoint(1100px) {
	.media-object-cover .text {
		padding-left: 45px;
	}
	.media-object-cover.flip .text {
		padding-right: 45px;
	}
}