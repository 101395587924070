.body-wrapper {
	position: relative;
	&.relative-nav .page-header {
		position: relative;
	}
}
.off-canvas-nav {
	background-color: $brand;
	width: 290px;
	height: 100%;
	padding-right: 20px;
	position: absolute;
	visibility: hidden;
	z-index: 1;
	transition: all .3s;
	&::after {
		display: none;
	}
	.nav-header {
		margin-bottom: 38px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.nav-toggle {
			padding-left: 20px;
		}
		img {
			margin: 0;
		}
	}
	nav {
		padding-left: 20px;
		li {
			font-size: rem-calc(16);
			padding-left: 0;
			margin-bottom: 7px;
			&:last-child {
				margin-bottom: 17px;
			}
		}
		a, .dropdown {
			&:hover, &:focus {
				color: $yellow;
			}
		}
		> ul > li {
			margin-top: 17px;
		}
		> ul > li > a,
		> ul > li > .dropdown {
			font-family: $alt-font;
			font-weight: $alt-bold;
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.25;
			margin-bottom: 7px;
			transition: color .3s;
		}
		.dropdown {
			display: inline-block;
			position: relative;
			&:hover {
				cursor: pointer;
			}
			&::after {
				@include css-triangle(5px, $brand-accent, down);
				margin-left: 10px;
				display: inline-block;
				vertical-align: middle;
				transition: transform .3s;
			}
		}
		.dropdown.selected {
			color: $yellow;
			&::after {
				transform: rotate(-180deg);
			}
		}
		.dropdown-child {
			margin: 0;
			display: none;
			height: 0;
			overflow: hidden;
			transition: height .3s ease-in-out;
			&.is-visible {
				height: auto;
				display: block;
				transition: height .3s ease-in-out;
			}
			li:first-child {
				margin-top: 7px;
			}
			.selected > a {
				color: $yellow;
			}
		}
		.highlight-tag {
			font-family: $heading-font;
			font-size: rem-calc(14);
			font-weight: $heading-bold;
			background-color: $white;
			color: $brand;
			line-height: 1;
			padding: 1px 6px 4px;
			margin-left: 8px;
			display: inline-block;
		}
	}
	.cta {
		background-color: $white;
		border-radius: 0 0 $border-radius;
		padding: 20px;
		margin-top: 27px;
		h2 {
			font-size: rem-calc(17);
			color: $brand;
			margin-bottom: 6px;
		}
		p {
			@include font-size(small);
			margin-bottom: 10px;
		}
	}
}
.body-content {
	background-color: $off-white;
	transition: transform .3s;
	position: relative;
	z-index: 100;
}
.body-wrapper.side-nav-open {
	overflow: hidden;
	.off-canvas-nav {
		visibility: visible;
	}
	.body-content {
		transform: translate3d(290px, 0, 0);
		transition: transform .3s;
		&::before {
			content: '';
			background-color: $black-trans;
			width: 100%;
			height: 100%;
			display: block;
			z-index: 500;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}