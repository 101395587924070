.article {
	h2 {
		margin-top: 50px;
	}
	img {
		margin: 10px 0 50px;
		border-radius: 10px;
		@include breakpoint(500px) {
			width: auto;
		}
	}
}