.aside-callout-section {
	padding: 48px 0;
	.inner {
		max-width: 1030px;
	}
	.main-item {
		max-width: 335px;
		background-color: $brand;
		color: $white;
		border-radius: 0 0 $border-radius;
		padding: 30px;
		margin: 0 auto 36px;
		h2 {
			font-size: rem-calc(20);
			color: $white;
			line-height: 1.25;
			margin-bottom: 11px;
		}
		p {
			font-size: rem-calc(16);
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
			margin: 0;
		}
	}
	.aside-item {
		max-width: 500px;
		margin: 0 auto;
		.aspect-item {
			margin-bottom: 36px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		h3 {
			font-size: rem-calc(24);
			line-height: 1.17;
			color: $brand;
			display: flex;
			align-items: center;
			.icon {
				padding-left: 10px;
			}
			img {
				height: 30px;
				margin: 0;
			}
		}
		p {
			color: $brand;
			margin: 0;
		}
	}
}

.aside-callout-section.full-width {
	.inner {
		max-width: 85rem;
	}
}

@include breakpoint(800px) {
	.aside-callout-section {
		.inner {
			display: grid;
			align-items: center;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 72px;
		}
		.aside-item {
			max-width: 100%;
		}
	}
	.aside-callout-section.full-width .inner {
		grid-template-columns: auto 1fr;
		align-items: start;
	}
}

@include breakpoint(large) {
	.aside-callout-section {
		padding: 120px 0;
		.main-item {
			max-width: 414px;
			padding: 40px 55px;
			margin-bottom: 0;
			h2 {
				font-size: rem-calc(25);
				line-height: 1.2;
			}
		}
		.aside-item {
			.aspect-item {
				margin-bottom: 30px;
			}
			h3 {
				font-size: rem-calc(36);
				margin-bottom: 7px;
			}
		}
	}
	.aside-callout-section.full-width .inner {
		grid-template-columns: auto 1fr;
		align-items: start;
		.main-item {
			max-width: 500px;
		}
	}
}

//Aside callout section contained
.aside-callout-section.rounded {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	margin-left: -1.25rem;
	margin-right: -1.25rem;
	.button {
		margin-top: 36px;
	}
}

@include breakpoint(medium) {
	.aside-callout-section.rounded {
		padding-left: 1.875rem;
		padding-right: 1.875rem;
		margin-left: -1.875rem;
		margin-right: -1.875rem;
	}
}

@include breakpoint(800px) {
	.aside-callout-section.rounded {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 72px;
	}
}

@include breakpoint(large) {
	.aside-callout-section.rounded {
		padding: 108px;
	}
}

@include breakpoint(1300px) {
	.aside-callout-section.rounded {
		border-radius: $border-radius;
		margin-left: auto;
		margin-right: auto;
	}
}

// Media section full width
.media-section-full {
	background: linear-gradient(79deg, #004D58 0%, #00353C 100%);
	color: $white;
	h2 {
		color: $white;
	}
	.media {
		width: 100%;
		max-width: 544px;
		padding-top: 30px;
		margin: 0 auto;
		img {
			margin: 0;
		}
	}
	.text {
		width: 100%;
		max-width: 570px;
		padding-top: 30px;
		padding-bottom: 30px;
		margin: 0 auto;
	}
}

@include breakpoint(800px) {
	.media-section-full {
		.inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 50px;
		}
		.media {
			margin: 0;
			flex-basis: 544px;
			align-self: flex-end;
		}
		.text {
			flex-basis: 570px;
			margin: 0;
		}
	}
}

// Media section full width with contained text
.media-section-full-contained {
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 30px;
	&.text-section-only {
		.inner {
			min-height: 400px;
			padding-top: 30px;
			padding-bottom: 30px;
			display: flex;
			align-items: center;
		}
		.text {
			margin-bottom: 0;
		}
	}
	.text {
		background: $brand-trans;
		color: $white;
		padding: 30px;
		margin-bottom: 30px;
		h2 {
			color: $white;
		}
	}
	.media {
		text-align: center;
		img {
			max-width: 300px;
			margin: 0;
		}
	}
	&.wide-image {
		.media img {
			width: 100%;
			max-width: 400px;
		}
	}
	&.bg-structure-construction {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=500&fit=crop');
		background-color: #7e8b91;
	}
	&.bg-field-tractor {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=500&fit=crop');
		background-color: #7e8b91;
	}
	&.bg-event-setup {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=500&fit=crop');
		background-color: #4d433d;
	}
	&.bg-house-frame {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=500&fit=crop');
		background-color: #c29462;
	}
	&.bg-outdoor-concert {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=500&fit=crop');
		background-color: #c29462;
	}
	&.bg-wedding-dinner {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=500&fit=crop');
		background-color: #edd7ac;
	}
	&.bg-smoke-forest {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=500&fit=crop');
		background-color: #edd7ac;
	}
	&.bg-outdoor-event {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=500&fit=crop');
		background-color: #697453;
	}
	&.bg-portapros-team {
		background-image: url('https://portapros.imgix.net/media-sections-full/bg/about_portapros.jpg?auto=format&w=500&fit=crop');
		background-color: #c8bdbc;
	}
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		&.bg-structure-construction {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-field-tractor {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-event-setup {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-house-frame {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-outdoor-concert {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-wedding-dinner {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-smoke-forest {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-outdoor-event {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
		&.bg-portapros-team {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/about_portapros.jpg?auto=format&w=500&fit=crop&dpr=2&q=30');
		}
	}
}


@include breakpoint(500px) {
	.media-section-full-contained {
		&.bg-structure-construction {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-field-tractor {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-event-setup {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-house-frame {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-outdoor-concert {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-wedding-dinner {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-smoke-forest {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-outdoor-event {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=1000&fit=crop');
		}
		&.bg-portapros-team {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/about_portapros.jpg?auto=format&w=1000&fit=crop');
		}
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			&.bg-structure-construction {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-field-tractor {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-event-setup {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-house-frame {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-outdoor-concert {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-wedding-dinner {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-smoke-forest {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-outdoor-event {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
			&.bg-portapros-team {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/about_portapros.jpg?auto=format&w=1000&fit=crop&dpr=2&q=30');
			}
		}
	}
}

@include breakpoint(780px) {
	.media-section-full-contained {
		.inner {
			display: flex;
			align-items: center;
			gap: 30px;
		}
		.text {
			flex-basis: 775px;
			order: 1;
			margin-bottom: 0;
		}
		.media {
			flex-basis: 495px;
			order: 0;
			align-self: flex-end;
			img {
				max-width: 100%;
			}
		}
		&.flip {
			.media {
				order: 2;
			}
		}
		&.wide-image {
			h2 {
				font-size: rem-calc(24);
			}
			.media {
				flex-basis: 650px;
				img {
					max-width: 100%;
				}
			}
			.text {
				flex-basis: 580px;
				max-width: 580px;
				margin-bottom: 30px;
				p {
					font-size: rem-calc(18);
				}
			}
		}
	}
}

@include breakpoint(1130px) {
	.media-section-full-contained {
		&.bg-structure-construction {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-field-tractor {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-event-setup {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-house-frame {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-outdoor-concert {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-wedding-dinner {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-smoke-forest {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=1440&fit=crop');
		}
		&.bg-outdoor-event {
			background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=1440&fit=crop');
		}

		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			&.bg-structure-construction {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/structure_construction.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-field-tractor {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/field_tractor.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-event-setup {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/event_setup.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-house-frame {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/house_frame.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-outdoor-concert {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_concert.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-wedding-dinner {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/wedding_dinner.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-smoke-forest {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/smoke_forest.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
			&.bg-outdoor-event {
				background-image: url('https://portapros.imgix.net/media-sections-full/bg/outdoor_event.jpg?auto=format&w=1440&fit=crop&dpr=2&q=30');
			}
		}
		.inner,
		&.text-section-only .inner {
			min-height: 700px;
		}
		.text p {
			font-size: rem-calc(23);
			line-height: 1.56;
		}
	}
}