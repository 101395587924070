.product-details {
	max-width: 600px;
	padding-bottom: 30px;
	margin: 60px auto 0;
	display: flex;
	flex-direction: column;
	gap: 30px;
	h1 {
		.subhead {
			font-size: .7em;
			color: $brand;
		}
	}
	.main-image {
		width: 100%;
		height: auto;
		margin: 0 auto 30px;
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		img {
			margin: 0;
			&.loading {
				opacity: 0;
			}
		}
	}
	.product-gallery {
		display: grid;
		grid-gap: 14px;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		button {
			cursor: pointer;
			&:hover img, &:focus-visible img {
				border-color: $brand-alt;
			}
		}
		img {
			background: $white;
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
			border-radius: 2px;
			margin: 0;
			transition: border-color .3s;
			border: solid 2px transparent;
			&.selected {
				border-color: $brand-alt;
			}
			&.pad {
				padding: 10px;
			}
		}
	}
	.gallery-loading-spinner {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		svg {
			width: 150px;
			height: auto;
		}
	}
	.add-to-cart {
		margin-bottom: 40px;
		.action {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			gap: 12px;
			#add-to-cart-quantity {
				background-color: $off-white;
				width: 76px;
				height: 68px;
				font-size: rem-calc(28);
				font-weight: $main-bold;
				color: $brand;
				text-align: center;
				border: 2px solid #E0E0E0;
				margin: 0;
			}
			button {
				border: 0;
				box-shadow: none;
			}
		}
		.warning {
			margin-top: 15px;
		}
	}
	.product-dimensions {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		b {
			color: $brand;
		}
		.divider {
			color: #B6BCC1;
		}
	}
	.rental-variant-buttons {
		margin-bottom: 20px;
		display: grid;
		gap: 28px;
		@include breakpoint(500px) {
			grid-template-columns: 1fr 1fr;
		}
		@include breakpoint(1140px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		button {
			width: 100%;
			max-width: 250px;
			background: $white;
			font-family: $heading-font;
			font-weight: $heading-bold;
			color: $brand;
			border: 2px solid #D3D3D3;
			border-radius: 11px;
			margin: 0 auto;
			position: relative;
			cursor: pointer;
			flex-basis: 226px;
			display: flex;
			flex-direction: column;
			transition: border-color .3s;;
			&:hover,
			&:focus,
			&.active {
				border-color: $brand;
				.footer {
					background: $brand;
					color: $white;
				}
			}
		}
		.image {
			width: 100%;
			height: 50%;
			padding: 36px 18px 18px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			img {
				margin: 0;
			}
		}
		.footer {
			width: 100%;
			min-height: 50%;
			height: auto;
			background: #F1F2F2;
			padding: 10px 15px 20px;
			border-radius: 0 0 8px 8px;
			transition: background-color .3s, color .3s;
		}
		.tag {
			background: $brand;
			font-family: $heading-font;
			font-size: rem-calc(14);
			font-weight: $heading-bold;
			color: $white;
			line-height: 1;
			border-radius: 0 11px 0 9px;
			padding: 5px 10px 8px;
			position: absolute;
			top: -2px;
			right: -2px;
			z-index: 40;
			&.light {
				background-color: #21A1AF;
			}
		}
		.price {
			font-size: rem-calc(24);
			line-height: 1;
			margin-bottom: 10px;
		}
		.title {
			font-size: rem-calc(18);
			line-height: 1.16;
		}
	}
}

@include breakpoint(800px) {
	.product-details {
		max-width: 100%;
		display: flex;
		flex-direction: row;
		gap: 60px;
		.media {
			flex-basis: 460px;
		}
		.product-info {
			flex-basis: 747px;
		}
	}
}

.back-to-products-link {
	a {
		font-family: $heading-font;
		font-weight: $heading-bold;
		font-size: rem-calc(18);
		color: $brand;
		display: inline-flex;
		align-items: center;
		gap: 4px;
		color: transition .3s;
		&:hover, &:focus {
			color: $brand-accent;
		}
	}
}