/*----------------------------------------------------------*\
   Images

   Default image styling and various image helpers. The
   floating helpers will not float on smaller views. To
   enable an image floating on all views, use the -all
   suffixed classes.

\*----------------------------------------------------------*/

img {
	margin-bottom: 15px;
}

/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}
.img-full.img-center {
	margin: 0 auto 15px;
	display: block;
}
.img-shadow {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.img-rounded {
	border-radius: 5px;
}

@media screen and #{breakpoint(medium)} {
	.img-left {
		float: left;
		margin-right: 20px;
	}
	.img-right {
		float: right;
		margin-left: 20px;
	}
}

/* @end */