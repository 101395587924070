/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/
$imgcdn: 'https://portapros.imgix.net';

//Font families
$sans-serif-font: 'Etica', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sans-serif-font-alt: 'MetaPro', 'Lucida Grande', sans-serif;
$serif-font: sans-serif;
$icon-font: 'StarterTemplate';
$heading-font: $sans-serif-font-alt;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

$base-spacing: 24px;

//Font sizes
$font-sizes: (
  small: (rem-calc(13), 1.38),
  default: (rem-calc(16), 1.5),
  large: (rem-calc(20), 1.5),
  small-desktop: (rem-calc(14), 1.6),
  default-desktop: (rem-calc(18), 1.6),
  large-desktop: (rem-calc(28), 1.5)
);


//Styleguide colors
$brand:				 #004D58;
$brand-accent:		 #62D0DF;
$brand-alt:			 #004D58;
$brand-alt-accent:	 #62D0DF;
$light-blue:			#B9E9EA;
$dark-green:			#00748C;
$purple:             #6F5E80;
$red:				 #BA5454;
$off-black:			 #2A2A2A;
$gray:               #7C7C81;
$darker-gray:		 #393b3d;
$dark-gray:			 #515457;
$light-gray:		 #A7A6A4;
$extra-light-gray:		 #F0F0F0;
$white:              #FFFFFF;
$black:              #000000;
$off-white:			 $extra-light-gray;
$football-orange:	 #D54208;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'light-blue':        $light-blue,
	'dark-green':        $dark-green,
	'purple':            $purple,
	'red':             	 $red,
	'off-black':         $off-black,
	'off-white':         $off-white,
	'gray':              $gray,
	'darker-gray':       $darker-gray,
	'dark-gray':         $dark-gray,
	'light-gray':        $light-gray,
	'extra-light-gray':        $extra-light-gray,
	'white':             $white,
	'black':             $black,
	'football-orange':   $football-orange
);

//Gradients, for use on backgrounds only
$gradients: (
	'brand': linear-gradient(79deg, #004D58 0%, #00353C 100%),
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.9);
$black-trans:        rgba($black,0.8);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $brand;

//Link colors
$link-base: $dark-green;
$link-active: $brand;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow-brand: 0 1px 1px rgba(0, 109, 121, 0.5);
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow-subtle: 0 0 4px rgba(0, 0, 0, 0.2);
$box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
$box-shadow-heavy: 0 0 70px rgba(0, 0, 0, 0.2);

//Rounded corners
$border-round: 1000px;
$border-radius: 17px;

//Section spacing
$full-section-spacing-small: 		48px;
$content-section-spacing-small: 	96px;
$content-sub-section-spacing-small: 72px;
$content-sub-section-short-spacing-small: 36px;
$intro-section-spacing-small: 		48px;
$full-section-spacing: 				72px;
$content-section-spacing: 			108px;
$content-sub-section-spacing: 		72px;
$content-sub-section-short-spacing: 36px;
$intro-section-spacing: 			54px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test,
	'farmers-field': farmers_field,
	'construction-workers-talking': construction_workers_talking,
	'penitentiary-toilet-row': penitentiary_toilet_row,
	'park-fence-line': park_fence_line,
	'trailer-corn-field': trailer_corn_field,
	'downtown-boise': downtown_boise,
	'man-roofing': man_roofing,
	'concert-crowd': concert_crowd,
	'happy-married-couple': happy_married_couple,
	'portapros-truck': portapros_truck,
	'basketball-dunk': basketball_dunk,
	'gray-toilet-row-perspective': gray_toilet_row_perspective,
	'smokey-mountain': smokey_mountain,
	'ski-team': ski_team,
	'toilet-hand-wash-box': toilet_hand_wash_box,
	'exec-restroom-trailer-white': exec_restroom_trailer_white,
	'ag-trailer-toilets': ag_trailer_toilets,
	'travis-leaning': travis_leaning,
	'grove-hand-wash-stations': 'grove_hand_wash_stations'
);

$hero-bg-color: (
	'home-orange': #ff9900,
	'farmers-field': #d7d8dd,
	'construction-workers-talking': #abb4bb,
	'penitentiary-toilet-row': #555c66,
	'park-fence-line': #17675a,
	'trailer-corn-field': #909a65,
	'downtown-boise': #fee2ba,
	'man-roofing': #bed9ee,
	'concert-crowd': #070709,
	'happy-married-couple': #ece59d,
	'portapros-truck': #485e2f,
	'basketball-dunk': #c5c8cd,
	'gray-toilet-row-perspective': #b4a892,
	'smokey-mountain': #b4a892,
	'ski-team': #2c3f5d,
	'exec-restroom-trailer-white': #2c3f5d,
	'ag-trailer-toilets': #d8c8ae,
	'travis-leaning': #d8c8ae
);