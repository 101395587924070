.product-card-nav {
	background-color: $brand-alt;
	h2 {
		color: $white;
		text-align: center;
		margin-bottom: 30px;
		@include breakpoint(medium) {
			margin-bottom: 50px;
		}
	}
}

.product-card-nav-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
	gap: 30px;
	.item {
		width: 100%;
		max-width: 200px;
		background: $white;
		text-align: center;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
		border-radius: 4px;
		padding: 40px 20px 20px;
		margin: 0 auto;
		display: block;
		position: relative;
		&:hover, &:focus {
			h3 {
				color: $brand-accent;
			}
		}
		.tag {
			background-color: #21A1AF;
			font-family: $heading-font;
			font-size: rem-calc(14);
			font-weight: $heading-bold;
			color: $white;
			line-height: 1;
			border-radius: 0 0 4px 4px;
			padding: 3px 10px 6px;
			white-space: nowrap;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		.image {
			height: 120px;
			margin-bottom: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				margin: 0;
			}
		}
		h3 {
			font-size: rem-calc(20);
			color: $brand;
			margin-bottom: 0;
			transition: color .3s;
		}
	}
}