/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/

 
/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/

@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			//color: darken($color,10%);
		}
	}
}

/* @end Social Media Link Colors */

/*------------------------------------*\
    @group Hero image Sizes
\*------------------------------------*/
@mixin hero-image($filename,$extension: jpg) {
  background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '.' + $extension + '?auto=format');
  @media screen and #{breakpoint(medium)} {
    & {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_med.' + $extension + '?auto=format');
    }
  }
  @media screen and #{breakpoint(large)} {
	& {
	  background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_lrg.' + $extension + '?auto=format');
	}
  }
}

@mixin hero-image-retina($filename,$extension: jpg) {
  background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '.' + $extension + '?auto=format');
  @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
    background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '@2x.' + $extension + '?auto=format&q=20');
  }
  @include breakpoint(medium) {
    & {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_med.' + $extension + '?auto=format');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_med@2x.' + $extension + '?auto=format&q=20');
      }
    }
  }
  @include breakpoint(large) {
    & {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_lrg.' + $extension + '?auto=format');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_lrg@2x.' + $extension + '?auto=format&q=20');
      }
    }
  }
}

@mixin hero-form-image-retina($name, $filename,$extension: jpg) {
  .hero-content-wrapper {
    background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '.' + $extension + '?auto=format');
    background-color: map-get($hero-bg-color, $name);
    @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 120dpi) {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '@2x.' + $extension + '?auto=format&q=20');
    }
  }
  @include breakpoint(medium) {
    .hero-content-wrapper {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_med.' + $extension + '?auto=format');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_med@2x.' + $extension + '?auto=format&q=20');
      }
    }
  }
  @include breakpoint(830px) {
    & {
      background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_lrg.' + $extension + '?auto=format');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/hero/bg_' + $filename + '_lrg@2x.' + $extension + '?auto=format&q=20');
      }
    }
    .hero-content-wrapper {
      background: none;
    }
  }
}

/* @end Hero Images */

/*------------------------------------*\
    @group Font Sizes
\*------------------------------------*/
@mixin font-size($font-size) {
  $item: map-get($font-sizes, $font-size);
  font-size: nth($item, 1);
  line-height: nth($item, 2);
}

/* @end Font Sizes */

/*------------------------------------*\
    @group Backgound section images
\*------------------------------------*/
@mixin background-section-image($filename,$extension: jpg) {
  background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '.' + $extension + '?auto=format');
  @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
    background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '@2x.' + $extension + '?auto=format&q=20');
  }
  @include breakpoint(large) {
    & {
      background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '_lrg.' + $extension + '?auto=format');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '_lrg@2x.' + $extension + '?auto=format&q=20');
      }
    }
  }
}
/* @end Backgound section images */

/*------------------------------------*\
    @group Backgound section images
\*------------------------------------*/
@mixin background-section-image-cdn($filename, $extension, $smallWidth, $smallHeight, $largeWidth, $largeHeight) {
  background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '.' + $extension + '?auto=format&w=' + $smallWidth + '&h=' + $smallHeight + '&fit=crop');
  @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi) {
    background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '.' + $extension + '?auto=format&q=20&dpr=2&w=' + $smallWidth + '&h=' + $smallHeight + '&fit=crop');
  }
  @include breakpoint(large) {
    & {
      background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '.' + $extension + '?auto=format&w=' + $largeWidth + '&h=' + $largeHeight + '&fit=crop');
      @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
      only screen and (-o-min-device-pixel-ratio: 13/10),
      only screen and (min-resolution: 120dpi) {
        background-image: url('https://portapros.imgix.net/media-sections/' + $filename + '.' + $extension + '?auto=format&q=20&dpr=2&w=' + $largeWidth + '&h=' + $largeHeight + '&fit=crop');
      }
    }
  }
}
/* @end Backgound section images */