@font-face{
    font-family: 'Etica';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/EticaRegular.eot?#iefix");
    src: url("/fonts/EticaRegular.eot?#iefix") format("eot"),
         url("/fonts/EticaRegular.woff2") format("woff2"),
         url("/fonts/EticaRegular.woff") format("woff"),
         url("/fonts/EticaRegular.ttf") format("truetype");
}
@font-face{
    font-family: 'Etica';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/EticaBold.eot?#iefix");
    src: url("/fonts/EticaBold.eot?#iefix") format("eot"),
         url("/fonts/EticaBold.woff2") format("woff2"),
         url("/fonts/EticaBold.woff") format("woff"),
         url("/fonts/EticaBold.ttf") format("truetype");
}
@font-face{
    font-family: 'MetaPro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/MetaProRegular.eot?#iefix");
    src: url("/fonts/MetaProRegular.eot?#iefix") format("eot"),
         url("/fonts/MetaProRegular.woff2") format("woff2"),
         url("/fonts/MetaProRegular.woff") format("woff"),
         url("/fonts/MetaProRegular.ttf") format("truetype");
}
@font-face{
    font-family: 'MetaPro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/MetaProBold.eot?#iefix");
    src: url("/fonts/MetaProBold.eot?#iefix") format("eot"),
         url("/fonts/MetaProBold.woff2") format("woff2"),
         url("/fonts/MetaProBold.woff") format("woff"),
         url("/fonts/MetaProBold.ttf") format("truetype");
}
