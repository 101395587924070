.callout-tag {
	background: $brand;
	font-family: $alt-font;
	font-size: rem-calc(14);
	color: $white;
	line-height: 1;
	padding: 7px 8px 9px;
	display: inline-block;
	&.tag-right {
		margin-left: 5px;
	}
}