.team-bio-heading {
	font-size: rem-calc(24);
	margin-bottom: 32px;
	text-transform: uppercase;
}

.team-bios {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 48px;
	margin-bottom: 72px;
	&:last-child {
		margin-bottom: 0;
	}
}
.bio {
	max-width: 450px;
	margin: 0 auto;
	.image {
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: relative;
		&.image-attach-left {
			justify-content: flex-start;
		}
		&.image-attach-right {
			justify-content: flex-end;
			padding-left: 20px;
			padding-right: 0;
		}
	}
	img {
		margin: 0;
		position: relative;
		z-index: 10;
	}
	.text {
		display: flex;
		justify-content: space-between;
		h3 {
			margin: 0;
		}
		.name {
			color: $brand;
		}
		.title {
			font-size: rem-calc(17);
			color: $brand-accent;
			display: block;
		}
	}
	.bio-trigger {
		padding: 20px;
		display: block;
		transition: background .3s, box-shadow .3s;
		&:hover, &:focus {
			background-color: $white;
			box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
		}
	}
}

@include breakpoint(750px) {
	.team-bios {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 60px;
	}
}

@include breakpoint(large) {
	.team-bios {
		grid-template-columns: repeat(3, 1fr);
	}
}

.team-hero {
	background: linear-gradient(315deg, #006D79 0%, #19B0A3 100%);
	color: $white;
	padding-top: 120px;
	margin-bottom: 50px;
	h1 {
		font-size: rem-calc(40);
		color: $white;
	}
	.media {
		max-width: 550px;
		background-color: $purple;
		border-radius: 0px 0px 30px 0px;
		margin-bottom: -100px;
		overflow: hidden;
		position: relative;
		img {
			margin: 0;
		}
	}
	.media-text {
		max-width: 380px;
		background: $darker-gray;
		color: $white;
		padding: 15px 20px;
		border-radius: 0 0 20px;
		position: absolute;
		right: 20px;
		bottom: 20px;
		.name {
			font-size: rem-calc(20);
			font-weight: $main-bold;
			line-height: 1.25;
			margin-bottom: 0;
		}
		p {
			font-size: rem-calc(14);
			line-height: 1.6;
			margin: 0;
		}
	}
}

@include breakpoint(830px) {
	.team-hero {
		margin-bottom: 100px;
		.inner {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
			align-items: center;
		}
	}
}

@include breakpoint(1100) {
	.team-hero {
		.inner {
			grid-template-columns: 1fr 550px;
			grid-gap: 114px;
		}
		h1 {
			font-size: rem-calc(80);
			margin-bottom: 30px;
		}
		p {
			font-size: rem-calc(28);
		}
		.media-text .name {
			font-size: rem-calc(22);
			margin-bottom: 4px;
		}
	}
}


.team-media-object {
	margin-bottom: 50px;
	.media {
		text-align: center;
		margin-bottom: 20px;
		img {
			width: auto;
			margin: 0;
		}
	}
	p {
		margin: 0;
	}
}

@include breakpoint(640px) {
	.team-media-object {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		.media {
			margin-bottom: 0;
		}
		.text {
			padding-left: 30px;
		}
	}
	.team-media-object.flip {
		.media {
			order: 1;
		}
		.text {
			padding-left: 0;
			padding-right: 30px;
		}
	}
}

@include breakpoint(1100px) {
	.team-media-object .text {
		padding-left: 45px;
	}
	.team-media-object.flip .text {
		padding-right: 45px;
	}
}


.team-collage {
	text-align: center;
	img {
		margin-bottom: 35px;
	}
}

@include breakpoint(700px) {
	.team-collage-row {
		display: flex;
		> img:first-child {
			width: 60%;
			padding-right: 18px;
		}
		> img:last-child {
			width: 40%;
			padding-left: 18px;
		}
	}
	.team-collage-row.flip {
		> img:first-child {
			width: 40%;
			padding-right: 18px;
		}
		> img:last-child {
			width: 60%;
			padding-left: 18px;
		}
	}
}
