.grid-2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px 30px
}

.grid-col-2 {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	> div {
		max-width: 615px;
		width: 100%;
		margin: 0 auto;
	}
	@include breakpoint(830px) {
		grid-template-columns: 1fr 1fr;
		gap: 50px;
	}
}

@include breakpoint(large) {
	.grid-2 {
		grid-gap: 50px 115px;
	}
}

.grid-3 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 50px 40px;
}