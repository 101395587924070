.product-cards {
	margin: 40px auto;
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	@include breakpoint(750px) {
		grid-template-columns: 1fr 1fr;
	}
	@include breakpoint(950px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.product-card {
	width: 100%;
	max-width: 425px;
	background: $white;
	text-align: center;
	padding: 36px 30px;
	margin: 0 auto;
	box-shadow: 0 4px 9px rgba(0, 0, 0, 0.08);
	position: relative;
	display: flex;
	flex-direction: column;
	.card-content {
		margin-top: auto;
	}
	.tag {
		background: $brand;
		font-family: $heading-font;
		font-size: rem-calc(14);
		font-weight: $heading-bold;
		color: $white;
		line-height: 1;
		padding: 5px 10px 8px;
		border-radius: 0 0 0 11px;
		position: absolute;
		top: 0;
		right: 0;
	}
	.title {
		font-size: rem-calc(24);
		line-height: 1.25;
		margin-bottom: 10px;
	}
	.price {
		font-family: $heading-font;
		font-size: rem-calc(20);
		font-weight: $heading-bold;
		color: $brand;
		margin-bottom: 20px;
	}
	.optional-action {
		padding-top: 8px;
		button {
			// font-weight: $main-bold;
			color: $dark-green;
			cursor: pointer;
			border-bottom: solid 2px $dark-green;
			padding-bottom: 2px;
			transition: color .3s, border-color .3s;
			&:hover, &:focus {
				color: $brand;
				border-color: $brand;
			}
		}
	}
}

// Product details cards
.product-detail-cards {
	display: grid;
	gap: 54px;
}

@include breakpoint(920px) {
	.product-detail-cards {
		grid-template-columns: 1fr 1fr;
	}
}

@include breakpoint(1200px) {
	.product-detail-cards {
		gap: 108px 90px;
	}
}

.product-detail-card {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	.heading {
		font-size: rem-calc(20);
		font-weight: $alt-bold;
		color: $brand;
		text-wrap: pretty;
		line-height: 1.25;
		margin: 0 0 14px;
	}
	.tag {
		background: $brand;
		font-weight: $main-bold;
		font-size: 1rem;
		color: $white;
		line-height: 1;
		border-radius: 40px;
		padding: 6px 13px;
		display: inline-block;
		&.alt {
			background-color: $brand-accent;
			color: $brand;
		}
	}
}

.product-detail-card-container {
	container-name: product-detail-card;
	container-type: inline-size;
	.callout-banner {
		margin-top: 30px;
		margin-bottom: 0;
	}
}

.product-detail-cards.stacked-cards {
	grid-template-columns: 1fr;
	.product-detail-card {
		max-width: 636px;
		flex-wrap: wrap;
		margin: 0 auto;
	}
}

@container product-detail-card (min-width: 25em) {
	.product-detail-card {
		flex-direction: row;
		gap: 30px;
		.image {
			flex-basis: 194px;
		}
		.content {
			flex-basis: 372px;
			flex-grow: 1;
		}
	}
	.product-detail-cards.stacked-cards {
		.product-detail-card {
			.image {
				flex-basis: 100%;
				img {
					margin: 0;
				}
			}
			.content {
				flex-basis: 100%;
			}
		}
	}
}


@include breakpoint(800px) {
	.product-detail-card {
		max-width: 615px;
	}
	.product-detail-cards.stacked-cards {
		.product-detail-card {
			max-width: 100%;
			flex-wrap: nowrap;
			margin: 0;
			.image {
				flex-basis: 50%;
			}
			.content {
				flex-basis: 50%;
			}
		}
	}
}

@include breakpoint(large) {
	.product-detail-card {
		.heading {
			font-size: rem-calc(25);
			line-height: 1.2;
		}
	}
}
