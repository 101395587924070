.section-heading + .text-grid {
	margin-top: 48px;
}

.text-grid {
	display: grid;
	grid-gap: 32px 32px;
	.text-grid-item {
		padding: 20px 25px;
		box-shadow: $box-shadow;
		p {
			margin: 0;
		}
	}
	.heading {
		color: $brand;
		margin-bottom: 10px;
	}
}

.text-grid.bare {
	.text-grid-item {
		padding: 0;
		box-shadow: none;
	}
}

@include breakpoint(300px) {
	.text-grid {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}

.icon-text-grid	{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 24px;
	@include breakpoint(900px) {
		grid-gap: 30px 60px;
	}
}

// Text grid with image header
.text-grid-image-header {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	grid-gap: 30px;
	img {
		border: solid 6px $white;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
	}
	h3 {
		color: $brand;
		text-align: center;
		margin-bottom: 10px;
	}
	.item {
		max-width: 414px;
		margin: 0 auto;
	}
}