.comparison-table {
	overflow-x: auto;
	margin-bottom: 30px;
	table {
		min-width: 600px;
		margin: 0;
	}
	td, th {
		border: solid 1px #D9D9D9;
		padding: 20px 10px;
	}
	th {
		background: $extra-light-gray;
		font-family: $heading-font;
		font-size: rem-calc(14);
		color: $dark-green;
		text-align: center;
		line-height: 1.25;
		a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		img {
			max-height: 60px;
			width: auto;
			margin-bottom: 10px;
		}
		.text {
			display: block;
			line-height: 1.4;
		}
	}
	tr th:first-child,
	tr td:first-child {
		width: 33%;
		position: sticky;
		left: 0;
		top: 0;
	}
	tr td:first-child {
		font-family: $heading-font;
		font-size: rem-calc(14);
		font-weight: $heading-bold;
		color: $dark-green;
		text-align: left;
	}
	td {
		background: $white;
		font-size: rem-calc(14);
		text-align: center;
	}
	.table-option {
		display: block;
		margin: 0 auto;
	}
}

@include breakpoint(large) {
	.comparison-table {
		th {
			padding: 30px 30px;
		}
		td {
			font-size: rem-calc(18);
			padding: 16px 30px;
		}
		th {
			font-size: rem-calc(20);
			img {
				max-height: 100px;
			}
		}
		tr td:first-child {
			font-size: rem-calc(20);
		}
	}
}