.rental-info {
	display: grid;
	grid-gap: 30px;
	@include breakpoint(800) {
		grid-template-columns: 1fr 1fr;
	}
}

.rental-info-media {
	text-align: center;
	@include breakpoint(800) {
		order: 1;
		img {
			width: auto;
		}
	}
}

.rental-price-callout {
	background: $brand;
	color: $brand-accent;
	padding: 20px;
	border-radius: 0 0 $border-radius;
	h2 {
		font-family: $main-font;
		font-size: rem-calc(60);
		font-weight: $main-bold;
		color: $white;
		line-height: 1;
		margin-bottom: 0;
	}
	p {
		color: $white;
	}
}